<template>
    <div class="height:100%">
        <v-list class="height:100%" shaped :disabled="this.$store.state.disabledlist">
            <v-list-item-group v-model="selectedItem">
                <div v-for="(item, index) in this.$store.state.Resources" :key = "index" >
                        <ListItem :items="item" ></ListItem>
                </div>
            </v-list-item-group>
         </v-list>
    </div> 
  </template>
  
  <script>
  import ListItem from '../ListItem.vue'

  export default {
    components:{
        ListItem,
    },
    data: () => ({
                selectedItem: 1,
                resources: [],
             }),
    methods: {
        isclickedItemList(index){
            // this.$store.dispatch('setResourceID',index)
            // this.$store.dispatch('APIgetresourcesSchedule', this.getCurrentDate())
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
            const day = String(today.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
    }
         
  }
  </script>
  
  <style scoped>
  .bar{
      width: 100%;
      height: 100%;
      background-color: #fff6a4; /* Set the background color as desired */
      overflow-y: auto; /* Make it scrollable if necessary */
  }
  .card{
    background: #a4ffaa;
    overflow-y: auto;
    height: 500px;
  }
  .list-item{
    padding-left: 2px;
  }
  </style>