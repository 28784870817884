import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './css/style.css'; // Path to your global CSS file

import VueCryptojs from 'vue-cryptojs'
// components
import SubGroup from './components/ListsComponents/SubGroup.vue'
import Loading from './components/Loading.vue'

Vue.component('sub-component', SubGroup);
Vue.component('v-loading', Loading);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  VueCryptojs,
  render: h => h(App)
}).$mount('#app')