<template>
    <div>
        <NewFormEdit :FormEditData="bookEditData"></NewFormEdit>
    </div>
</template>

<script>
import NewFormEdit from '@/components/NewFormEdit.vue';
export default {
    components: {
        NewFormEdit,
    },
    computed:{
        bookEditData(){
            return this.$store.state.bookEditData
        }
    }

}
</script>

<style>

</style>