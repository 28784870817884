// src/store/search.js

import axios from 'axios'


// Contact Number: +60 18-983 3327
// Book by: DRJOHN
// Time From: 8:00am
// Time To: 8:30am
// Created by: PHYSIOLAB
// Remark: 

// Initial state for the search module
const state = {
    searchResultData: [],
    tableData: [
      {
        Title: 'MRP (MMC Panel Review Meeting)',
        Description: "TAHIR BIN TALIB 600915-02-5329",
        TimeFrom: 6.0,
        TimeTo: 24,
        Bookby: "Johan",
      },
    ],
    tableHeader: [
      {
        text: 'Title',
        align: 'start',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Time From',
        align: 'start',
        sortable: false,
        value: 'start',
      },      
      {
        text: 'Time To',
        align: 'start',
        sortable: false,
        value: 'end',
      },     
      {
        text: 'Book by',
        align: 'start',
        sortable: false,
        value: 'bookby',
      },      

    ],
  };
  
  // Mutations to update the state
  const mutations = {
    setSearchResultData(state, searchData) {
      state.searchResultData.push(searchData);
    },
    setClearSearchResultData(state){
      state.searchResultData = []  
    }
  };
  
  // Actions to perform asynchronous operations
  const actions = {
    setClearSearchResultData({commit}){
      commit('setClearSearchResultData')
    },
    async APIgetReservationSearch({ commit, rootState }, payload) {
      if(payload){

        const base64Payload = btoa(payload);
        
        var api = rootState.api + "search/" + base64Payload
        var session_token = localStorage.getItem("session_token")

        commit('setClearSearchResultData')
        
        try {
          const response = await axios.get(api, { headers: {
                                                                'CRCToken': session_token
                                                            } });
          response.data.data.forEach(item => {
                commit('setSearchResultData', {
                  id: item.serries_id,
                  name: item.description,
                  start: changeTimeZone(item.start_date),
                  end: changeTimeZone(item.end_date),
                  timed: true,
                  color: 'indigo',
                  title: item.title,
                  contactno: item.contactno,
                  bookby: item.bookby,
                  remark: item.remark,
                  owner_id: item.owner_id,
                  lname: item.lname,
                  fname: item.fname,
                  // text: item.title,
              })
          });
          // commit('setSearchResultData', response.data.messages);
        } catch (error) {
          console.error('Error:', error);
          console.log('Response:', error.response.data.messages);
        }
      }
    }
  };
  
  // Getters to access state properties
  const getters = {
    searchResultData: state => state.searchResultData,

    getTableData: state => state.searchResultData,

    getTableHeader: state => state.tableHeader

  };

  function changeTimeZone(dateString) {
    // 2023-12-08 02:20:00
    const [datePart, timePart] = dateString.split(' ');

    var datenew = datePart + 'T' + timePart + 'Z';

    const dateFromUTC = new Date(datenew); // 'Z' denotes UTC time

    // Convert the UTC date to a string in UTF-8 encoding
    const utf8Date = dateFromUTC.toLocaleString(
        'en-US',
        {timeZone: 'Asia/Kuching'}
    );

    return new Date(utf8Date)
}
  
  export default {
    state,
    mutations,
    actions,
    getters
  };