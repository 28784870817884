<template>
    <!-- <p>this is the booking page</p> -->
    <div>
        <CalendarControl></CalendarControl>
    </div>
    
    
</template>

<script>
import CalendarControl from '@/components/CalendarControl.vue';
export default {

    components: {
        CalendarControl,
    }

}
</script>

<style>

</style>