<template>
    <div>
      <v-row class="formtitle">
      <v-col cols="12"  class="formtextfield" style="padding-bottom: 0%;">
          <h3>Remark</h3>
        </v-col>
      <v-col cols="12" class="formtitle">
        <v-radio-group v-model="radioGroup">
          <v-radio v-for="(item, index) in Remark" 
           :key="index" 
           :label="item.value" 
           :value="item.value" 
           @change="test(item.value)"
      ></v-radio>
            <v-row  class="formtextfield">
              <v-radio label="Other" :value="otherValue" 
                  @change="test(otherValue)"></v-radio>
            <v-text-field 
               dense
                style="padding-left: 2em; padding-top:0%; margin-top: 0%;" 
                :placeholder="label"
                v-model="otherValue"
                @input="otherinput"
                :disabled="disabled"
                >
            </v-text-field>
            <!-- v-if="radioGroup === 'other'"/ -->
            </v-row>
        </v-radio-group>
      </v-col>
    </v-row>
</div>
</template>
  
  <script>
  export default {
    props:{
        defaultVModel:{
            default: null
        }
    },
    data() {
      return {
        radioGroup: '',
        label : "remark",
        Remark  : [
                    { id: '1', value: 'CRC' },
                    { id: '2', value: 'CRM' }
                    ],
        otherValue: 'others'
      };
    },
    computed:{
      disabled(){
        if(this.radioGroup == this.otherValue)
        {
          return false
        }
        else{
          return true
        }
      }
    },
    watch:{
      defaultVModel:{
            immediate: true, // Trigger the watcher immediately when the component is created
            handler(newVal) {

              const isDefaultInRemark = this.Remark.some(item => item.value === newVal);
              if(isDefaultInRemark)
              {
                this.radioGroup = newVal
              }
              else{
                this.radioGroup = newVal
                this.otherValue = newVal
              }
                this.$emit('Remark', this.radioGroup);

            },
        }
    },
    methods:{
      test(value){
        this.radioGroup = value
        this.$emit('Remark', this.radioGroup)
      },
      otherinput(){
        this.radioGroup = this.otherValue
        this.$emit('Remark', this.radioGroup)
      }
    }
  };
  </script>
  