<template>
  <div>
    <v-select
      outlined
      v-model="selectedRoom"
      :items="RoomSelect"
      item-text="name" 
      item-value="resource_id" 
      label="Room"
      multiple
      @input="OnInput"
    >
   <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
       <span
          v-if="index === 0 "
          class="grey--text text-caption"
        >
          (+{{ selectedRoom.length - 1 }} others)
        </span>
      </template> 
      
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="selectedRoom.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
    props: {
        RoomSelect: {
                        type: Array,
                        default: [],
                    }
    },
    data: () => ({
      selectedResourceID: [],
      selectedRoom: [],
    }),
    computed: {
      selectAll () {
        return this.selectedRoom.length === this.RoomSelect.length
      },
      unselectAll () {
        return this.selectedRoom.length > 0 && !this.selectAll
      },
      icon () {
        if (this.selectAll) return 'mdi-close-box'
        if (this.unselectAll) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    methods: {
      toggle () {
        this.$nextTick(() => {
          if (this.selectAll) {
            this.selectedRoom = []
          } else {
            this.selectedRoom = this.RoomSelect.map(room => room.resource_id).slice();
          }
          this.OnInput()
        })
      },
      OnInput(){
        this.$emit("getRoom", this.selectedRoom)
      }
    },
}
</script>

<style>

</style>