<template>
        <div >

          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
              <v-card elevation="12">
                <v-card-text>
                  <v-container>
                    <v-row justify="center">
                      <v-col>
                        <h2 class="text-h2">Book Now</h2>
                        <p class="subtitle-1">Explore our services and book your appointment!</p>
                        
                        <!-- Explore Button -->
                        <v-btn @click="explore" color="primary">Explore</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
  </template>
  
  <script>
  export default {
    methods: {
      explore() {
        // Use Vue Router to navigate to the login route
        this.$router.push('/login');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional styles for your card or page here */
  .text-h2 {
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
  
  .subtitle-1 {
    font-size: 1.2rem;
    color: #555;
  }
  </style>
  