import axios from 'axios'

const state = {
    reportitle: "this is the title",
    reporResultData: [],
    tableHeader : [
        {
            text: 'series_id',
            align: 'start',
            sortable: false,
            value: 'series_id',
            width: 20
        },
        {
            text: 'date_created',
            align: 'start',
            sortable: false,
            value: 'date_created'
        },
        {
            text: 'last_modified',
            align: 'start',
            sortable: false,
            value: 'last_modified'
        },
        {
            text: 'title',
            align: 'start',
            sortable: false,
            value: 'title',
            width: 120

        },
        {
            text: 'description',
            align: 'start',
            sortable: false,
            value: 'description'
        },
        {
            text: 'allow_participation',
            align: 'start',
            sortable: false,
            value: 'allow_participation'
        },
        {
            text: 'allow_anon_participation',
            align: 'start',
            sortable: false,
            value: 'allow_anon_participation',
        },
        {
            text: 'type_id',
            align: 'start',
            sortable: false,
            value: 'type_id'
        },
        {
            text: 'status_id',
            align: 'start',
            sortable: false,
            value: 'status_id'
        },
        {
            text: 'repeat_type',
            align: 'start',
            sortable: false,
            value: 'repeat_type'
        },
        {
            text: 'repeat_options',
            align: 'start',
            sortable: false,
            value: 'repeat_options'
        },
        {
            text: 'owner_id',
            align: 'start',
            sortable: false,
            value: 'owner_id'
        },
        {
            text: 'legacyid',
            align: 'start',
            sortable: false,
            value: 'legacyid'
        },
        {
            text: 'last_action_by',
            align: 'start',
            sortable: false,
            value: 'last_action_by'
        },
        {
            text: 'contactno',
            align: 'start',
            sortable: false,
            value: 'contactno'
        },
        {
            text: 'bookby',
            align: 'start',
            sortable: false,
            value: 'bookby'
        },
        {
            text: 'remark',
            align: 'start',
            sortable: false,
            value: 'remark'
        },
        {
            text: 'start_date',
            align: 'start',
            sortable: false,
            value: 'start_date'
        },
        {
            text: 'end_date',
            align: 'start',
            sortable: false,
            value: 'end_date'
        },
        {
            text: 'resource_id',
            align: 'start',
            sortable: false,
            value: 'resource_id'
        },
        {
            text: 'fname',
            align: 'start',
            sortable: false,
            value: 'fname'
        },
        {
            text: 'lname',
            align: 'start',
            sortable: false,
            value: 'lname'
        },
        {
            text: 'resource_type_id',
            align: 'start',
            sortable: false,
            value: 'resource_type_id'
        }
    ]



}

const actions  = {

    setClearReportResultData({commit}){
        commit('setClearReportResultData')
      },
    
    async ApiPostReport({ commit, rootState }, payload){

        const base64Payload = btoa(payload.resource_id);

        var api = rootState.api + "report"
        var session_token = localStorage.getItem("session_token")

        commit('setClearReportResultData')

        try {

            console.log(payload)
            const response = await axios.post(api, payload, { headers: {
                                                                  'CRCToken': session_token
                                                              } });
            response.data.data.forEach(item => {
                  commit('setReportResultData', {
                    series_id: item.series_id,
                    date_created: item.date_created,
                    last_modified: item.last_modified,
                    title: item.title,
                    description: item.description,
                    allow_participation: item.allow_participation,
                    allow_anon_participation: item.allow_anon_participation,
                    type_id: item.type_id,
                    status_id: item.status_id,
                    repeat_type: item.repeat_type,
                    repeat_options: item.repeat_options,
                    owner_id: item.owner_id,
                    legacyid: item.legacyid,
                    last_action_by: item.last_action_by,
                    contactno: item.contactno,
                    bookby: item.bookby,
                    remark: item.remark,
                    start_date: item.start_date,
                    end_date: item.end_date,
                    resource_id: item.resource_id,
                    fname: item.fname,
                    lname: item.lname,
                    resource_type_id: item.resource_type_id
                })
            });
            // commit('setSearchResultData', response.data.messages);
          } catch (error) {
            console.error('Error:', error);
            console.log('Response:', error.response.data.messages);
          }

    }

}

const mutations = {
    setReportResultData(state, reportData){
        state.reporResultData.push(reportData);
    },
    setClearReportResultData(state){
        state.reporResultData = []  
    }
}

const getters = {
    getTitle: state => state.reportitle,
    getReportTableHeader: state => state.tableHeader,
    getReportTableData: state => state.reporResultData
}

export default {
    state,
    mutations,
    actions,
    getters
  };