<template>
  <v-card color="grey lighten-3" min-width="350px" flat>
    <v-toolbar :color="this.$store.state.calenderevent.color" dark>
      <v-toolbar-title> ID : {{ this.$store.state.calenderevent.id }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="CloseDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      Title: {{ this.$store.state.calenderevent.title }}
      <br> Description: {{ this.$store.state.calenderevent.name }}
      <br> Contact Number: {{ this.$store.state.calenderevent.contactno }}
      <br> Book by: {{ this.$store.state.calenderevent.bookby }}
      <br> Time From: {{ formatTime(this.$store.state.calenderevent.start) }}
      <br> Time To: {{ formatTime(this.$store.state.calenderevent.end) }}
      <br> Created by: {{ this.$store.state.calenderevent.fname }} {{ this.$store.state.calenderevent.lname }}
      <br> Remark: {{ this.$store.state.calenderevent.remark }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn 
          text 
          color="secondary"
          @click="editbtn" 
          :disabled="checkcolor">
        <v-icon>mdi-pencil</v-icon>
        <!-- Edit -->
      </v-btn>
      <v-btn 
          text 
          color="secondary" 
          @click="checkcancel" 
          :disabled="checkcolor">
        <v-icon>mdi-delete</v-icon>
        <!-- Cancel Appointment -->
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog"
     persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Cancel Appointment
        </v-card-title>
        <v-card-text>Are you sure want to cancel this appointment?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="CancelAppointment">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      electedElement: null,
      selectedOpen: true,
      dialog: false,
    }),
    computed: {
      checkcolor() {
        var loginid = localStorage.getItem('user_id');
        var calenderid = this.$store.state.calenderevent.owner_id;
        var authorise = this.$store.state.User.authorise
        var isGroupAdminManager
        var isScheduleAdminManager
        

        if (authorise)
        {
          isScheduleAdminManager = authorise.some(item => item.name === 'Schedule Administrators');
          isGroupAdminManager = authorise.some(item => item.name === 'Group Administrators');
        }
        const isOwnUser = loginid === calenderid;
        console.log(authorise)
        console.log(isOwnUser)
        if ((isOwnUser) || isScheduleAdminManager || isGroupAdminManager)
        {
          return false
        } 
        else
        {
          return true
        } 

        if (this.$store.state.calenderevent.color == 'grey') {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      CloseDialog() {
        // this.$store.commit('calendardialog');
        this.$emit("Cancel")
      },
      formatTime(dateString) {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12; // Convert 0 to 12
        // Construct the formatted time string
        const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')}${ampm}`;
        return formattedTime;
      },
      checkcancel() {
        this.dialog = !this.dialog
      },
      editbtn() {
        var reserve_id = this.$store.state.calenderevent.id
        var url = '/edit?id=' + reserve_id;
        this.$router.push(url).catch(() => {});;
      },
      CancelAppointment() {
        this.dialog = !this.dialog
        this.$store.dispatch('APIcancelReservations', this.$store.state.calenderevent.id)
      }
    }
  }
</script>

<style>

</style>