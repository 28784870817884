import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import CryptoJS from 'crypto-js'
import searchModule from './search'
import reportModule from './report'

Vue.use(Vuex)

const namespaced = true

export default new Vuex.Store({
    namespaced,
    modules:{
        search: searchModule,
        report: reportModule
    },
    state: {

        api:  'https://booking.crcsgh.com/api/',   //'http://localhost:8080/', //  
        redirectdate: '',
        Headers: {
            'CRC-Token': 'YourCRCTokenHere', // Replace with your CRC-Token valueresultdialog
        },
        AppBar: {
            title: "CRCKuching"
        },
        delaytime: 800,
        CalendarLoading: false,
        drawernavi: true,

        // Reset passrod
        reset_user_id: '',
        reset_username: '',
        passwordbtndisable: false,
        resetresulttext: '',

        // Login page
        LoginDialog: false, // Initial state value
        LoginError: false,
        LoginErrortext: '',
        LoginButtonLoading: false,
        CancelButtonDisable: false,
        // user user information
        User: {
            username: '',
            user_id: '',
            user_role: '',
            fname: '',
            lname: '',
            email: '',
            authorise: [],

        },
        // form booking

        bookingRoomId: '',
        FormButtonLoading: false,
        bookFromTime: [],
        bookendTime: '',

        // form edit booking
        FormEditLoading: false,
        FormeditButtonLoading: false,
        bookFromTime: [],
        bookendTime: '',
        bookEditData: {
            title: '',
            description: '',
            phonenumber: '',
            bookedby: '',
            remark: '',
            participant: '',
            Frmtimes: '',
            Totimes: '',
            selected_resourceid: '',
            datecreated: '',
            resource_type_id: ''
        },

        //list
        disabledlist: false,

        //reservation dialog
        reservationdialogdata: [],
        returndialog: false,

        ///
        calendarhead: {
            name: ''
        },
        calendardialog: false,
        calenderevent: {},
        calendarSelectDate: '',
        Resources: [],
        ResourceID: "",
        ReservationSchedule: [],
        schedule: []
    },
    getters: {
        getuserid() {
            var id = localStorage.getItem("user_id")
            if (id == "" && id == null) {
                return true
            } else {
                return false
            }
        },
        encrypt: (state) => (value) =>{
                // Encrypt
            var myvalue = value.toString()
            var secretkey  = 'crc123'
            console.log("encrypt")
            var ciphertext = CryptoJS.AES.encrypt(myvalue,secretkey).toString();
            return ciphertext

        },
        decrypt: (state) => (value) => {
            // Decrypt
            var myvalue = value.toString()
            var secretkey  = 'crc123'
            var bytes  = CryptoJS.AES.decrypt(value, secretkey).toString();;

            return bytes; // 'my message'
        }
    },
    mutations: {
        setUserInformation(state){
            state.User = {};
            state.User.user_id = localStorage.getItem("user_id")    
            state.User.email = localStorage.getItem("email")
            state.User.fname = localStorage.getItem("fname")
            state.User.lname = localStorage.getItem("lname")
            state.User.username = localStorage.getItem("username")
            state.User.authorise = JSON.parse(localStorage.getItem('authorise'))

        },
        setUserInformationClear(state){
            state.User = {};
            state.User.user_id = ""
            state.User.email = ""
            state.User.fname = ""
            state.User.lname = ""
            state.User.username = ""
        },
        setcalendarhead(state, data) {
            state.calendarhead.name = data
        },
        setBookingRoomID(state, data) {
            state.bookingRoomId = data
        },
        setpasswordbtndisable(state, data) {
            state.passwordbtndisable = data
        },
        setreset_user_id(state, data) {
            state.reset_user_id = data
        },
        setreset_resulttext(state, data) {
            state.resetresulttext = data
        },
        setreset_username(state, data) {
            state.reset_username = data
        },
        setredirectdate(state, data) {
            state.redirectdate = data
        },
        setApiFetchedFlag(state, flag) {
            state.apiRessourceFetched = flag;
        },
        setDrawerControl(state, newValue) {
            state.drawernavi = newValue;
        },
        CalendarLoading(state) {
            state.CalendarLoading = !state.CalendarLoading
        },
        // edit
        FormEditLoading(state) {
            state.FormEditLoading = !state.FormEditLoading
        },
        CalendarEditLoading(state) {
            state.CalendarEditLoading = !state.CalendarEditLoading
        },
        updateformTitle(state, data) {
            state.bookEditData.title = data
        },
        updateformselected_resourcetypeid(state, data) {
            state.bookEditData.resourcetypeid = data
        },
        updateformDescription(state, data) {
            state.bookEditData.description = data
        },
        updateformContact(state, data) {
            state.bookEditData.phonenumber = data
        },
        updateformbookby(state, data) {
            state.bookEditData.bookedby = data
        },
        updateformremark(state, data) {
            state.bookEditData.remark = data
        },
        updateformFrmtimes(state, data) {
            state.bookEditData.Frmtimes = data
        },
        updateformTotimes(state, data) {
            state.bookEditData.Totimes = data
        },
        updateformselected_resourceid(state, data) {
            state.bookEditData.selected_resourceid = data
        },
        updateformdatecreated(state, data) {
            state.bookEditData.datecreated = data
        },
        //endedit
        navidrawer(state) {
            state.drawernavi = !state.drawernavi
        },
        toggleLoginDialog(state) {
            state.LoginDialog = !state.LoginDialog;
        },
        toggleLoginButtonLoading(state) {
            state.LoginButtonLoading = !state.LoginButtonLoading;
        },
        toggleCancelButtonDisable(state) {
            state.CancelButtonDisable = !state.CancelButtonDisable;
        },
        toogleFormButtonLoading(state) {
            state.FormButtonLoading = !state.FormButtonLoading;
        },
        toogleFormEditButtonLoading(state) {
            state.FormeditButtonLoading = !state.FormeditButtonLoading;
        },
        toggleLoginError(state, data) {
            state.LoginError = true;
            state.LoginErrortext = data;
        },
        clearLoginStatus(state) {
            state.LoginError = false;
            state.LoginErrortext = '';
        },
        calendardialog(state, data) {
            state.calendardialog = data
        },
        calenderevent(state, data) {
            state.calenderevent = data
        },
        setResources(state, data) {
            state.Resources = data
        },
        setResourceID(state, data) {
            state.ResourceID = data
        },
        setReservationSchedule(state, data) {
            state
                .ReservationSchedule
                .push(data)
        },
        clearReservationSchedule(state) {
            state.ReservationSchedule = []
        },
        setbookFromTime(state, data) {
            state
                .bookFromTime
                .push(data);
        },
        clearbookFromTime(state) {
            state.bookFromTime = []
        },
        setbookendTime(state, data) {
            state.bookendTime = data
        },
        clearbookendTime(state) {
            state.bookendTime = ''
        },
        setdisablelist(state, data) {
            state.disabledlist = data
        },
        clearreservationdialogdata(state) {
            state.reservationdialogdata = []
        },
        setreservationdialogdata(state, data) {
            state.reservationdialogdata = data
        },
        setreturndialog(state, data) {
            state.returndialog = data
        },
        deletereservationlist(state, data) {
            state
                .ReservationSchedule
                .forEach(reservation => {
                    if (reservation.id === data) {
                        reservation.color = 'grey';
                    }
                })
        }
    },
    actions: {
        setResourceID({
            commit
        }, data) {
            commit('setResourceID', data);
        },
        async APIcancelReservations({
            state,
            commit,
            dispatch
        }, payload) {
            var series_id = payload;
            var session_token = localStorage.getItem("session_token")
            var api = state.api + 'reservations/' + series_id
            await axios
                .delete(api, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {
                    console.log(response.data)
                    commit('deletereservationlist', series_id)
                    window
                        .location
                        .reload();
                })
                .catch(error => {
                    console.log(error)
                    // if(error.response && error.response.status === 401){ } else
                    // if(error.response.status === 404 && error.response.data.messages[0] == "No
                    // data"){     console.log("no Data") } An error occurred, handle the error
                    console.error('Error:', error);
                    console.log('Response:', error.response.data.messages);

                });
        },
        async APIgetBookingEndTime({
            state,
            commit,
            dispatch
        }, payload) {
            var resource_id = payload.resource_id;
            var dateFrom = payload.start_date;
            var timeFrom = payload.from_time;
            var session_token = localStorage.getItem("session_token")

            var api = state.api + 'book/' + resource_id + '/' + dateFrom + '/' +
                    changetoZeroTimezone(timeFrom)
            // console.log(api)
            commit('clearbookendTime')
            commit('toogleFormButtonLoading')

            await axios
                .get(api, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {

                    if (response.data.messages[0] == 'No data') {
                        console.log('no booking')
                        commit('setbookendTime', '17:00:00')
                        // commit('updateformTotimes', '17:00:00')

                    } else {
                        // console.log('no booking',response.data.data.start_time ) Request was
                        // successful, handle the response data
                        commit(
                            'setbookendTime',
                            changeTimeZone_timeonly(response.data.data.start_time)
                        )
                        // commit('updateformTotimes',
                        // changeTimeZone_timeonly(response.data.data.start_time))
                    }
                })
                .catch(error => {
                    console.log(error)
                    // if(error.response && error.response.status === 401){ } else
                    // if(error.response.status === 404 && error.response.data.messages[0] == "No
                    // data"){     console.log("no Data") } An error occurred, handle the error
                    console.error('Error:', error);
                    console.log('Response:', error.response.data.messages);

                });
            commit('toogleFormButtonLoading')
        },
        async APIgetBookingStartTime({
            state,
            commit,
            dispatch
        }, payload) {
            var resource_id = payload.resource_id;
            var dateFrom = payload.start_date;
            var session_token = localStorage.getItem("session_token")

            var api = state.api + 'book/' + resource_id + '/' + dateFrom

            commit('clearbookFromTime')
            commit('toogleFormButtonLoading')
            await axios
                .get(api, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {
                    console.log(response.data.data)
                    if (response.data.messages[0] == 'No data') {
                        console.log('no booking')
                    } else {
                        // Request was successful, handle the response data
                        response
                            .data
                            .data
                            .forEach(item => {
                                item.start_time = changeTimeZone_timeonly(item.start_time)
                                item.end_time = changeTimeZone_timeonly(item.end_time)
                                commit('setbookFromTime', item)
                            })
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response && error.response.status === 401) {
                        console.log("session exipred");
                        dispatch('userLogout');
                        commit('toggleLoginDialog');
                    } else if (error.response.status === 404 && error.response.data.messages[0] == "No data") {
                        console.log("no Data")
                    }
                    // An error occurred, handle the error
                    console.error('Error:', error);
                    console.log('Response:', error.response.data.messages);

                });
            commit('toogleFormButtonLoading')
        },
        async APIgetReservationsSchedule({
            state,
            commit,
            dispatch
        }, payload) {
            // var resource_id =  window.location.hash.split('?room=')[1];
            var session_token = localStorage.getItem("session_token")
            // var date = payload if ((payload.date) && (payload.resource_id)){
            var api = state.api + 'reservations/' + payload.resource_id + '/' +
                    payload.date
            // }

            console.log(api)
            commit('CalendarLoading')
            commit('clearReservationSchedule')
            // if(resource_id){
            await axios
                .get(api, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {
                    // Request was successful, handle the response data
                    console.log('Response:', response);
                    // console.log('Response:', response.data.data);
                    // commit('setReservationSchedule', response.data)
                    response
                        .data
                        .data
                        .forEach(item => {
                            commit('setReservationSchedule', {
                                id: item.serries_id,
                                name: item.description,
                                start: changeTimeZone(item.start_date),
                                end: changeTimeZone(item.end_date),
                                timed: true,
                                color: 'indigo',
                                title: item.title,
                                contactno: item.contactno,
                                bookby: item.bookby,
                                remark: item.remark,
                                owner_id: item.owner_id,
                                lname: item.lname,
                                fname: item.fname
                            })
                        })
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log("session exipred");
                        console.log(error.response)
                        dispatch('userLogout');
                        commit('toggleLoginDialog');
                    } else if (error.response.status === 404 && error.response.data.messages[0] == "No data") {
                        console.log("no Data")
                    }
                    // An error occurred, handle the error
                    console.error('Error:', error);
                    console.log('Response:', error.response.data.messages);

                });
            setTimeout(() => {
                commit('CalendarLoading')
            }, state.delaytime);

        },
        async APIgetEditReservationsSchedule({
            state,
            commit,
            dispatch
        }, payload) {

            var session_token = localStorage.getItem("session_token")
            
            var api = state.api + 'reservations/' + payload
            console.log(api)
            commit("FormEditLoading")
            await axios
                .get(api, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {
                    // Request was successful, handle the response data

                    commit("updateformTitle", response.data.data.title)
                    commit("updateformDescription", response.data.data.description)
                    commit("updateformContact", response.data.data.contactno)
                    commit("updateformbookby", response.data.data.bookby)
                    commit("updateformremark", response.data.data.remark)
                    commit("updateformFrmtimes", getreturnedTimeonly(response.data.data.start_time))
                    commit("updateformTotimes", getreturnedTimeonly(response.data.data.end_time))
                    commit("updateformselected_resourceid", response.data.data.resource_id)
                    commit("updateformdatecreated", getYYYYMMDD(response.data.data.start_date))
                    commit( "updateformselected_resourcetypeid", response.data.data.resource_type_id )
                    
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log("session exipred");
                        console.log(error.response)
                        dispatch('userLogout');
                        commit('toggleLoginDialog');
                    } else if (error.response.status === 404 && error.response.data.messages[0] == "No data") {
                        console.log("no Data")
                    }
                    // An error occurred, handle the error
                    console.error('Error:', error);
                    console.log('Response:', error.response.data.messages);

                });
            setTimeout(() => {
                commit("FormEditLoading")
            }, state.delaytime);

        },
        async APIupdateReservationsSchedule({
            state,
            commit,
            dispatch
        }, data) {
            var session_token = localStorage.getItem("session_token")
            var api = state.api + "reservations";
            console.log('APIupdateReservationsSchedule', data)
            commit('toogleFormEditButtonLoading')
            commit('clearreservationdialogdata')
            await axios
                .patch(api, data, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {
                    console.log(response.data)

                    commit('setreservationdialogdata',response.data.messages[0] )
                    commit('setreturndialog', true)
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log("session exipred");
                        dispatch('userLogout');
                        commit('toggleLoginDialog');
                        console.log(error.response.data.messages)
                    }
                    console.error('Failed post ', error);
                });

            setTimeout(() => {
                commit('toogleFormEditButtonLoading')
            }, state.delaytime);

        },
        async APIpostReservationsSchedule({
            state,
            commit,
            dispatch
        }, data) {
            var session_token = localStorage.getItem("session_token")
            var api = state.api + "reservations";
            console.log(data)
            console.log(api)
            commit('toogleFormButtonLoading')
            commit('clearreservationdialogdata')
            await axios
                .post(api, data, {
                    headers: {
                        'CRCToken': session_token
                    }
                })
                .then(response => {
                    console.log(response.data)
                    commit('setreservationdialogdata', response.data.messages[0])
                    commit('setreturndialog', true)
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log("session exipred");
                        dispatch('userLogout');
                        commit('toggleLoginDialog');
                        console.log(error.response.data.messages)
                    }
                    console.error('Failed post ', error);
                });

            setTimeout(() => {
                commit('toogleFormButtonLoading')
            }, state.delaytime);

        },
        async apiresetpassword({
            state,
            commit,
            dispatch
        }, data) {

            var api = state.api + "users";

            commit('setpasswordbtndisable', true)
            commit('setreset_resulttext', '')
            await axios
                .patch(api, data)
                .then(response => {
                    console.log(response.data)
                    commit('setreset_resulttext', 'Password had been changed')
                    setTimeout(() => {
                        commit('setpasswordbtndisable', false)
                        window
                            .location
                            .reload();
                    }, state.delaytime);

                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log(error.response.data.messages)
                        commit(
                            'setreset_resulttext',
                            'there is an issue on change password, please try again'
                        )
                    }
                });

            setTimeout(() => {
                commit('setpasswordbtndisable', false)
                commit('setreset_resulttext', '')
            }, state.delaytime);

        },
        async userLogIn({state,commit,dispatch,getters}, data) {
            var api = state.api + "sessions";
            var dataRespond;

            commit('toggleLoginButtonLoading')
            commit('toggleCancelButtonDisable')

            await axios
                .post(api, data, {
                    header: {
                        'CRCToken': localStorage.getItem("session_token")
                    }
                })
                .then(response => {
                    console.log(response.data.messages[0])

                    if (response.data.messages[0].status_id == '2') {
                        console.log(response.data.messages[0].status_id)
                        commit('setreset_user_id', response.data.messages[0].user_id)
                        commit('setreset_username', response.data.messages[0].username)

                        window.location.href = '/#/user'; // Change the URL to the desired route

                    } else {
                        // console.log("nohash", response.data.messages[0].user_id)
                        
                        // var ecv = getters.encrypt(response.data.messages[0].user_id)
                        // console.log("encryupt",ecv)
                        // console.log("decryupt", getters.decrypt(ecv))
                         
                        localStorage.setItem("user_id", response.data.messages[0].user_id);
                        localStorage.setItem("session_token", response.data.messages[0].session_token);
                        localStorage.setItem("refresh_token", response.data.messages[0].refresh_token);
                        localStorage.setItem(
                            "user_session_id",
                            response.data.messages[0].user_session_id
                        );
                        localStorage.setItem("email", response.data.messages[0].email);
                        localStorage.setItem("fname", response.data.messages[0].fname);
                        localStorage.setItem("lname", response.data.messages[0].lname);
                        localStorage.setItem("username", response.data.messages[0].username);
                        localStorage.setItem('authorise', JSON.stringify(response.data.messages[0].authorise));
                        
                        

                        commit('setUserInformation');

                        dispatch('ApiGetResources')
                        window.location.href = '/#/'; // Change the URL to the desired route
                    }

                    // location.reload();
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log(error.response.data.messages)
                        dataRespond = error.response.data.messages
                        commit('toggleLoginError', dataRespond);
                    }
                    commit('toggleLoginError');
                    console.error('Login failed: ', error);
                });

            setTimeout(() => {
                commit('toggleLoginButtonLoading')
                commit('toggleCancelButtonDisable')
            }, state.delaytime);

        },
        async ApiGetResources({commit, state, actions, dispatch}) {

            var user_id = localStorage.getItem("user_id")
            var session_token = localStorage.getItem("session_token")
            var api = state.api + "resources/" + user_id;

            await axios
                .get(api, {
                    headers: {
                        'CRCToken': session_token
                    }
                },)
                .then(response => {
                    commit('setResources', response.data.data);
                    commit('setApiFetchedFlag', true);

                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        dispatch('userLogout');
                    } else {
                        console.error('Error:', error);
                        console.log(error.response.data.messages)
                    }
                });

        },
        userLogout({commit}) {
            localStorage.clear();
            commit('setUserInformationClear');
        },
        getDateFormat(data) {
            // Set the date and time for Nov 18, 2023, at 9 AM
            let date = new Date('11-15-2023 13:30');

            // Format the date to YYYY-MM-DD hh:mm
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            var formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}`;
            return formattedTimestamp;
        }
    }
})
function getFormattedDate(dateString) {

    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function changeTimeZone(dateString) {
    // 2023-12-08 02:20:00
    const [datePart, timePart] = dateString.split(' ');

    var datenew = datePart + 'T' + timePart + 'Z';

    const dateFromUTC = new Date(datenew); // 'Z' denotes UTC time

    // Convert the UTC date to a string in UTF-8 encoding
    const utf8Date = dateFromUTC.toLocaleString(
        'en-US',
        {timeZone: 'Asia/Kuching'}
    );

    return new Date(utf8Date)
}

function changeTimeZone_timeonly(dateString) {
    const zeroTime = dateString;

    const zeroTimeDate = new Date(`2020-01-01T${zeroTime}Z`);
    const utcPlus8Time = new Date(zeroTimeDate.getTime() + (8 * 60 * 60 * 1000)); // Add 8 hours (in milliseconds)

    return utcPlus8Time
        .toISOString()
        .substr(11, 8)
}

function changetoZeroTimezone(dateString) {
    const utcPlus8Time = dateString;

    const [hours, minutes, seconds] = utcPlus8Time
        .split(':')
        .map(Number);

    const utcPlus8TimeDate = new Date(
        Date.UTC(1970, 0, 1, hours, minutes, seconds)
    );

    const zeroTime = new Date(utcPlus8TimeDate.getTime() - (8 * 60 * 60 * 1000)); // Subtract 8 hours (in milliseconds)

    return zeroTime
        .toISOString()
        .substr(11, 8); // Displaying only time part

}
function getYYYYMMDD(dateTimeString) {
    // Create a Date object from the given datetime string
    const date = dateTimeString;
    // Get only the date part in "YYYY-MM-DD" format using toISOString() and
    // substr()
    return date.substr(0, 10);
}
function getreturnedTimeonly(datetime) {
    const date = new Date(`2020-01-01T${datetime}Z`);

    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
    const amOrPm = hours >= 12
        ? 'PM'
        : 'AM';
    const formattedHours = hours % 12 || 12;

    // Combine hours, minutes, and seconds in HH:mm:ss AM/PM format
    return `${padZero(formattedHours)}:${minutes} ${amOrPm}`;

}
function getreturnedTimeonlynoAMPM(datetime) {
    console.log("1")
    const date = new Date(`2020-01-01T${datetime}Z`);
    // console.log(new Date(date))

    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Combine hours, minutes, and seconds in HH:mm:ss AM/PM format
    return `${padZero(formattedHours)}:${minutes}`;

}
function padZero(value) {
    return value < 10
        ? `0${value}`
        : value;
}

