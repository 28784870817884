<template>
  <v-row justify="center">
    <v-dialog v-model="returndialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          <div>
            {{ checktitle }}
          </div>
         
        </v-card-title>
        <v-card-text>
          <!-- <v-progress-circular
                          :size="70"
                          :width="7"
                          color="purple"
                          indeterminate
                     ></v-progress-circular> -->
                     <div v-if="this.$route.path === '/NewBooking'">
                      <table class="table">
                        <tr>
                          <th>Reference number</th>
                          <td> : </td>
                          <td>{{ this.$store.state.reservationdialogdata.referenceNumber}}</td>
                        </tr>
                        <tr>
                          <th>Date created</th>
                          <td> : </td>
                          <td>{{ getDateFormat() }}</td>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      <p>Reservation changed success</p>
                    </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple darken-1" text @click="passvalue()">
            {{ buttonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props:{
      formeditdate: {
        default: null
      },
      formeditresourceid: {
        default: null
      }
    },
    data() {
      return {
        dialog: false,
        countdown: 5,
        intervalId: null,
        timerId: null
      }
    },
    computed: {
      returndialog() {
        return this.$store.state.returndialog;
      },
      buttonText() {
        return this.countdown > 0 ? `Check Calendar (${this.countdown}s)` : 'Check Calendar';
      },
      checktitle(){
        if (this.$route.path === '/NewBooking')
        {
          return 'Reservation Confirmed'
        }
        else {
          return 'Reservation Edit'
        }

        
      }

    },
    watch: {
      returndialog(newValue, oldValue) {
        if (newValue == true) {
          this.startAutoCloseTimer();
        }
      }
    },
    methods: {
      passvalue() {
        this.$store.commit('setreturndialog', false)
        this.clearAutoCloseTimer()
        this.timerEnded()
      },
      getDateFormat(data) {
        // Set the date and time for Nov 18, 2023, at 9 AM
        let date
        if (data) {
          date = new Date(data)
        } else {
          date = new Date();
        }
        // Format the date to YYYY-MM-DD hh:mm
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      startAutoCloseTimer() {
        this.intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            this.$store.commit('setreturndialog', false)
            clearInterval(this.intervalId);
            this.timerEnded(); // Call your method when the timer ends
          }
        }, 1000); // Decrease countdown every 1 second (1000 milliseconds)
      },
      timerEnded() {
        var resource_id = this.$store.state.reservationdialogdata.resourceID
        var startdate = this.$store.state.reservationdialogdata.dateReserved
        try {
          this.$store.commit('setredirectdate', this.getDateFormat(startdate))
          if(this.$route.path === '/NewBooking'){
          var url = '/Booking';
          if(resource_id){
            url = '/Booking?room=' + resource_id;
          }
          if(startdate)
          {
            url = '/Booking?room=' + resource_id + '&date=' + this.getDateFormat(startdate)
          }
          this.$router.push(url).catch(() => {});;
          }
          else{
             var url = '/Booking'
             if(this.formeditresourceid)
             {
               url = '/Booking?room=' + this.formeditresourceid;
             }
             if(this.formeditdate)
             {
               url = '/Booking?room=' + this.formeditresourceid + '&date=' + this.formeditdate;
             }
             
             this.$router.push(url).catch(() => {});;
            // this.$router.go(-1);
          }
          
        } catch (error) {
          console.error('Error fetching resources:', error);
        }
      },
      clearAutoCloseTimer() {
        clearInterval(this.intervalId)
      }
    }
  }
</script>

<style scoped>
  .table {
    text-align: left;
  }
</style>