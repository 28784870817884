<template>
    <div>
        <DashBoard v-if="auth"/>
        <v-sheet v-else>
            <h2>you have no authorise</h2>
        </v-sheet>
    </div>
</template>

<script>
import { computed } from 'vue'
import DashBoard from '../components/Reports/DashBoard.vue'
export default {
    components: {
        DashBoard
    },
    data:() => ({

    }),
    computed:{
        auth(){
            var loginid = localStorage.getItem('user_id');
            var calenderid = this.$store.state.calenderevent.owner_id;
            var authorise = this.$store.state.User.authorise
            var isGroupAdminManager
            var isScheduleAdminManager
            
            
            if (loginid == 2 || loginid == 14 || loginid == 16)
            {
            return true
            }
            else{
            return false
        }
        }
    }
}
</script>

<style>

</style>