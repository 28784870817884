<template>
    <Password></Password>
  </template>
  
  <script>
  import Password from '../components/ResetPassword.vue'
  export default {
      name: "User",
      components: {
        Password,
      }
  }
  </script>
  
  <style>
  
  </style>