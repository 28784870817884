<template>
    <div>
      <!-- Weekly Booking Overview -->
      <v-card class="report-card">
        <v-card-title>Report Overview</v-card-title>
        <v-card-text>
        <p>This report provides an overview of the weekly bookings in the CRC SGH Booking System. It includes data on room reservations, appointment schedules, and resource utilization.</p>
        <p>Key metrics and insights presented in this report:</p>
        <ul>
          <li>Total number of bookings made during the week</li>
          <li>Breakdown of bookings by room or facility</li>
          <li>Peak booking hours and days</li>
          <li>Comparison with previous weeks' booking trends</li>
        </ul>
        <p>By analyzing this information, administrators and managers can optimize resource allocation, improve scheduling efficiency, and enhance the overall user experience of the booking system.</p>
      </v-card-text>
        <v-card-actions>
            <v-btn outlined color="grey darken-2"  @click="goToReport" >Next</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        searchQuery: ''
      };
    },
    methods: {
      goToReport() {
            var url = '/Report'
            this.$router.push(url).catch(() => {});
      },
    }
  };
  </script>
  
  <style scoped>
  .report-card {
    margin-bottom: 20px;
  }
  </style>
  