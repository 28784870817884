<template>
    <v-row class="formtitle">
        <v-col cols="12" class="formtextfield">
            <h3>Description</h3>
        </v-col>
        <v-col cols="12" class="formtitle">
            <v-textarea
                outlined 
                v-model="description" 
                rows="3" 
                :rules="Rules" 
                :placeholder="label"
                required 
                hint="Key in description" 
                @input="onInput">
            </v-textarea>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            defaultVModel: {
                default: null
            }
        },
        data: () => ({
            description: "",
            label: "Patient Name, Study Name, IC",
            Rules: [
                v => !!v || '(Patient Name, Study Name, IC)',
                v => (v && v.length <= 85) || 'Name must be less than 85 characters',
            ],
        }),
        watch: {
            defaultVModel: {
                immediate: true, // Trigger the watcher immediately when the component is created
                handler(newVal) {
                    this.description = newVal;
                    this.$emit('description', this.description)
                },
            }
        },
        methods: {
            onInput() {
                if (this.description) {
                    this.description = this.description.toUpperCase();
                    this.$emit('description', this.description)
                }
            }
        }
    }
</script>

<style>

</style>