<template>
  <div class="text-center">
    <v-progress-circular indeterminate color="orange darken-2" size="60" width="10"></v-progress-circular>
  </div>
</template>

<script>
  export default {
  }
</script>

<style>

</style>