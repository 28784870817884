<template>
    <v-row class="formtitle">
        <v-col cols="3">
            <v-row>
                <h3>Date</h3>
            </v-row>
            <v-row>
                <v-menu 
                    v-model="menu" 
                    :close-on-content-click="false" 
                    :nudge-right="50" 
                    transition="scale-transition" 
                    offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="time.date"
                      :rules="RulesDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hint="Select a Date"
                    ></v-text-field>
                </template>
              <v-date-picker
                :min="setMinDate"
                v-model="time.date"
                @input="onInputDate"
              ></v-date-picker>
            </v-menu>
            </v-row>
        </v-col>
        <v-col cols="4" class="ml-2">
            <v-row>
                <h3>Start From</h3>
            </v-row>
            <v-row>
                <v-select
                outlined
                v-model="time.from"
                :items="fromtimes"
                :rules="RulesTimeFrom"
                hint="Time starting from"
                required
                @input="onInputTimeFrom"
              />
            </v-row>
        </v-col>
        <v-col cols="4"  class="ml-2">
            <v-row>
                <h3>To</h3>
            </v-row>
            <v-row>
                <v-select
                    outlined
                    v-model="time.To"
                    hint="Time Sending at"
                    :items="endtimes"
                    :rules="RulesTimeTo"
                    required
                    @input="onInputTimeTo"
              />
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            selectResourceID: {
                default: null // Set the default value as a string
            },
            defaultVModel: {
                default: null
            },
            FormEditData: {
                default: null
            }
        },
        data: () => ({
            menu: false,
            roomType: '',
            bookedtime: [],
            time: {
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                from: "",
                To: ""
            },
            RulesDate: [
                v => !!v || 'Date is required',
            ],
            RulesTimeFrom: [
                v => !!v || 'Time is required',
            ],
            RulesTimeTo: [
                v => !!v || 'Time is required',
            ],
        }),
        watch: {
            defaultVModel: {
                immediate: true, // Trigger the watcher immediately when the component is created
                handler(newVal) {
                    
                    this.time.date = newVal.date
                    this.time.from = newVal.from
                    this.time.To = newVal.to
                },
            },
            FormEditData: {
                immediate: true, // Trigger the watcher immediately when the component is created
                handler(newVal) {
                    // this.$emit('selectedTimefrom', this.time.To) 
                    this.$emit('selectedTimeTo', this.time.To)
                },
            },
        },
        created() {
            

            if (this.time.date) {
                this.onInputDate()
            }
        },
        computed: {
            setMinDate() {
                return  '2024-01-01'
                // return this.minDate()
            },
            fromtimes() {
                var bookedtime = this.$store.state.bookFromTime
                if (this.FormEditData) {
                    bookedtime = this.filterBookedstarttime(bookedtime)
                }
                return this.generateTimeOptions(bookedtime)
                // var sessionoption = []
                // sessionoption.push({ text: "Morning Session (8:00 AM - 12:00 PM)", value: "morning", disabled:false})
                // sessionoption.push({ text: "Afternoon Session (1:00 PM - 5:00 PM)", value: "afternoon", disabled:false})
                // sessionoption.push({ text: "Whole Day Session (8:00 AM - 5:00 PM)", value: "wholeDay", disabled:true})
                // return sessionoption
            },
            endtimes() {
                var bookedtime = this.$store.state.bookFromTime
                if (this.FormEditData) {
                    // bookedtime = this.filterBookedendtime(bookedtime)
                    // return this.generateEndTimeOptions1(bookedtime)
                } else {
                }
                return this.generateEndTimeOptions(bookedtime)
            },
        },
        methods: {
            filterBookedstarttime(bookedtime) {
                const startTimeToRemove = this.FormEditData.Frmtimes;
                if (bookedtime && bookedtime.length > 0) {
                    const bookedfiltered = bookedtime.filter(item => {
                        const formattedStartTime = this.convertTo12HourFormat(item.start_time);
                        return formattedStartTime !== startTimeToRemove;
                    });
                    return bookedfiltered;
                } else {
                    return bookedtime;
                }
            },
            filterBookedendtime(bookedtime, selectedtimeFrom, selectedtimeTo) {
                const timeselect = this.getformattedTime(selectedtimeFrom);
                if (bookedtime && bookedtime.length > 0) {
                    const bookedfiltered = bookedtime.filter(item => {
                        const bookedtimestart = new Date(`2000-01-01T${item.start_time}`); // loop this time
                        const bookedtimeend = new Date(`2000-01-01T${item.end_time}`); // loop this time
                        // console.log(timeselect,bookedtimestart , timeselect < bookedtimestart )
                        return ((timeselect < bookedtimestart));
                    });
                    return bookedfiltered;
                } else {
                    return bookedtime;
                }
            },
            onInputDate() {
                this.menu = false
                this.$emit('selectedDate', this.time.date)
            },
            onInputTimeFrom() {
                this.$emit('selectedTimeFrom', this.time.from)
            },
            onInputTimeTo() {
                this.$emit('selectedTimeTo', this.time.To)
            },
            minDate() {
                const today = new Date();
                const year = today.getFullYear();
                let month = today.getMonth() + 1;
                let day = today.getDate();
                // Pad month and day with leading zeros if needed
                month = month < 10 ? `0${month}` : month;
                day = day < 10 ? `0${day}` : day;
                return `${year}-${month}-${day}`;
            },
            getformattedTime(value) {
                const timeComponents = value.match(/(\d{2}):(\d{2}) ([ap]m)/i);
                if (timeComponents) {
                    const [, hour, minute, ampm] = timeComponents;
                    let selectedHour = parseInt(hour, 10);
                    if (ampm.toLowerCase() === 'pm' && selectedHour !== 12) {
                        selectedHour += 12;
                    }
                    const compareEndTime = new Date(2000, 0, 1, selectedHour, parseInt(minute, 10));
                    return compareEndTime; // Use toString() to get the formatted date string
                }
                return null;
            },
            generateEndTimeOptions(bookedtime) {
                const times = [];
                let currentHour = 8;
                let currentMinute = 30;
                var tempstarttime = this.time.from
                if (tempstarttime == '08:00 AM') {
                    tempstarttime = '07:30 AM'
                }
                var bookedtime = this.filterBookedendtime(bookedtime, this.time.from, this.time.To)
                while (!(currentHour === 17 && currentMinute === 30)) {
                    const timeString = ('0' + currentHour).slice(-2) + ':' + ('0' + currentMinute).slice(-2);
                    const formattedTime = this.convertTo12HourFormat(timeString);
                    let isDisabled = false;
                    if ((this.time.from) &&
                        (this.selectResourceID)) {
                        const compareEndTime = new Date(`2000-01-01T${timeString}`); // loop this time
                        const compareSelectedTime = this.getformattedTime(this.time.from); //time from
                        let bookuntiltime = this.getClosestEndTime(compareEndTime, bookedtime)
                        if ((compareEndTime <= compareSelectedTime) ||
                            (bookuntiltime)
                        ) {
                            isDisabled = true; // If the end time is before the selected time or matches booked start time, disable it
                        }
                    } else {
                        isDisabled = true;
                    }
                    const time = {
                        text: formattedTime,
                        value: formattedTime,
                        disabled: isDisabled
                    };
                    if (time.value == this.time.To) {
                        isDisabled = false;
                    }
                    times.push(time);
                    currentMinute += 30;
                    if (currentMinute === 60) {
                        currentHour++;
                        currentMinute = 0;
                    }
                }
                return times;
            },
            getClosestEndTime(compareEndTime, bookedtime) {
                let foundCloseTime = false;
                if (bookedtime.length > 0) {
                    bookedtime.some(({
                        start_time
                    }) => {
                        const startTime = new Date(`2000-01-01T${start_time}`);
                        if (compareEndTime >= startTime) {
                            foundCloseTime = true;
                            return true; // Stop iterating if disabled
                        }
                        return false;
                    });
                }
                return foundCloseTime;
            },
            generateTimeOptions(bookedtime) {
                const times = [];
                let currentHour = 8;
                let currentMinute = 0;
                while (!(currentHour === 17 && currentMinute === 0)) {
                    const timeString = ('0' + currentHour).slice(-2) + ':' + ('0' + currentMinute).slice(-2);
                    const formattedTime = this.convertTo12HourFormat(timeString);
                    let isDisabled = false;
                    bookedtime.some(({
                        start_time,
                        end_time
                    }) => {
                        const startTime = new Date(`2000-01-01T${start_time}`);
                        const endTime = new Date(`2000-01-01T${end_time}`);
                        const currentTime = new Date(`2000-01-01T${timeString}`);
                        // Check if the current time is within the booked start and end times
                        if (currentTime >= startTime && currentTime < endTime) {
                            isDisabled = true;
                            return true; // Stop iterating if disabled
                        }
                    });
                    //   if (this.$route.path === '/edit'){
                    //     if(this.FormEditData.Frmtimes == formattedTime)
                    //     {
                    //         isDisabled = false
                    //     }
                    //     }     
                    const time = {
                        text: formattedTime,
                        value: formattedTime,
                        disabled: isDisabled
                    };
                    times.push(time);
                    currentMinute += 30;
                    if (currentMinute === 60) {
                        currentHour++;
                        currentMinute = 0;
                    }
                }
                return times;
            },
            isExceedTimeFrom(checkTime, maxtime) {
                // Given times in string format (24-hour time)
                const time1 = checkTime; // Time to check
                const time2 = maxtime; // Reference time
                // Split the time strings into hours and minutes
                const [hours1, minutes1] = time1.split(':').map(Number);
                const [hours2, minutes2] = time2.split(':').map(Number);
                // Create Date objects to handle time comparison
                const date1 = new Date(2000, 0, 1, hours1, minutes1); // Arbitrary date used for time comparison
                const date2 = new Date(2000, 0, 1, hours2, minutes2); // Arbitrary date used for time comparison
                // Check if time1 is greater than time2
                if (date1.getTime() > date2.getTime()) {
                    return false
                } else {
                    return true
                }
            },
            isExcludedTime(time, bookedtime) {
                for (const slot of bookedtime) {
                    const startTime = slot.start_time.substring(0, 5); // Extract HH:MM from "HH:MM:SS"
                    const endTime = slot.end_time.substring(0, 5); // Extract HH:MM from "HH:MM:SS"
                    if (time >= startTime && time < endTime) {
                        return true;
                    }
                }
                return false;
            },
            convertTo12HourFormat(time) {
                const [hours, minutes] = time.split(':');
                let formattedTime = '';
                if (hours === '00') {
                    formattedTime = '12:' + minutes + ' AM';
                } else if (hours < 12) {
                    formattedTime = (hours.padStart(2, '0')) + ':' + minutes + ' AM';
                } else if (hours === '12') {
                    formattedTime = '12:' + minutes + ' PM';
                } else {
                    const twelveHourFormat = parseInt(hours) - 12;
                    formattedTime = (twelveHourFormat.toString().padStart(2, '0')) + ':' + minutes + ' PM';
                }
                return formattedTime;
            },
            //     convertTo24HourFormat(time) {
            //         const timeRegex = /^(\d{1,2}):(\d{2}) ([APap][Mm])$/; // Regex pattern for hh:mm AM/PM format
            //         const match = time.match(timeRegex);
            //         if (!match) {
            //         // Handle invalid time format
            //         return 'Invalid Time Format';
            //         }
            //         let [, hours, minutes, meridiem] = match;
            //         hours = parseInt(hours, 10);
            //         minutes = parseInt(minutes, 10);
            //         if (meridiem.toLowerCase() === 'pm' && hours !== 12) {
            //         hours += 12;
            //         } else if (meridiem.toLowerCase() === 'am' && hours === 12) {
            //         hours = 0;
            //         }
            //         const formattedHours = ('0' + hours).slice(-2);
            //         const formattedMinutes = ('0' + minutes).slice(-2);
            //         return `${formattedHours}:${formattedMinutes}:00`;
            //   },
        }
    }
</script>

<style scoped>
</style>