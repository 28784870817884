<template>
    <v-list-item>
        <v-list-item-content @click="clieckedme(items.resource_id)">
            <div class="item-content">
                <!-- <div class="list-icon">
                        <v-list-item-icon ><v-icon>mdi-home</v-icon></v-list-item-icon>
                    </div> -->
                <v-list-item-title class="list-item"> {{ items.name }}</v-list-item-title>
            </div>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            items: {
                default: '',
            }
        },
        data: () => ({
        }),
        methods: {
            async clieckedme(id) {
                this.$store.commit('setdisablelist', true)
                this.$store.commit('setcalendarhead', this.items.name)
                try {
                    var url = '/Booking?room=' + id;
                    this.$router.push(url).catch(() => {});;
                } catch (error) {
                    console.error('Error fetching resources:', error);
                }
                this.$store.commit('setdisablelist', false)
            },
            getCurrentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
                const day = String(today.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
        }
    }
</script>

<style scoped>
    .item-content {
        display: flex;
    }
    .list-icon {
        width: 20%;
    }
    .list-item {
        width: 80%;
        align-items: center;
        justify-content: left;
        /* Optional for horizontal alignment */
        padding-left: 1em;
        font-size: 1em;
    }
</style>