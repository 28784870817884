<template>
  <div>
    <span class="mr-2">{{ buttontext   }}</span>
    <v-icon>{{ icon }}</v-icon>
  </div>
</template>

<script>
  export default {
    data: () => ({
      icon: 'mdi-open-in-new',
      text: '',
    }),
    created(){
    },
    computed: {
      buttontext() {
        if (this.$store.state.User.user_id){
          return 'Sign Out'
        }
        else
        {
          return 'Sign In'
        }
      }
    }
  }
</script>

<style scoped>

</style>