<template>
    <div>
      <v-data-table
        :headers="getTableHeader"
        :items="getTableData"
        @click:row="handleClickEvent"
      >
      </v-data-table>
      <v-menu v-model="selectedOpen" 
        :close-on-content-click="false" 
         offset-x
         :position-x="mouseLocation.mouseX"
         :position-y="mouseLocation.mouseY"
         >
             <CalendarDialog @Cancel="CloseCalendarDialog"></CalendarDialog>
        </v-menu>
    </div>
  </template>
  
import { VDataTableHeader } from 'vuetify/lib'

<script>
import CalendarDialog from '../CalendarDialog.vue'

  export default {
    components:{
        CalendarDialog
    },
    data () {
      return {
        selectedOpen: false,
        selectedElement: null,
        mouseLocation: {
            mouseX: 0,
            mouseY: 0
        }
      }
    },
    computed:{
        getTableHeader(){
            return this.$store.getters['getTableHeader']
        },
        getTableData(){
            return this.$store.getters['getTableData']
        }
    },
    methods: {
      CloseCalendarDialog() {
        this.selectedOpen = false
      },
      handleClickEvent(any, data, event){
        const open = () => {
          this.selectedElement = data.item
          this.mouseLocation.mouseX = event.clientX + 15
          this.mouseLocation.mouseY = event.clientY + 20
          this.$store.commit('calenderevent', this.selectedElement);
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }
        if (this.selectedOpen) {
          this.selectedOpen = true
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        },
    },
  }
</script>
