<template>
   <v-app>
    <AppBar/> 
    <v-main class="main">
      <v-container fluid>
            <v-loading v-if="Loading"/>
            <MainContainer v-else/>
      </v-container>
    </v-main>
    
    <Footer/>
  </v-app>
</template>
<script>
import Footer from './components/Footer.vue';
import AppBar from './components/AppBar.vue';
import MainContainer from './components/MainContainer.vue';
import SelectBar from './components/ListsComponents/SelectBar.vue';
import Login from './components/Login.vue';

export default {
  name: 'App',
  components:{
    Footer,
    AppBar,
    SelectBar,
    MainContainer,
    Login,
  },
  data: () => ({
    Loading : false,
    overlay: true,
    useremail: '',
    avatartxt: '',
  }),

  created(){
    this.$store.commit('setDrawerControl', true)
    const sessiontoken = localStorage.getItem('session_token');

    // Check if the session token exists
    if( sessiontoken == null ){
      console.log('no session token, you need to log in')
      // this.$store.dispatch('userLogout')
    }
    else{
      //user is logged in
      this.$store.commit('setUserInformation')
      this.refresh()
    }  
  },
  computed: {
    Load() {
      return this.$store.state.CalendarLoading
    },
    myresource(){
        return this.$store.state.Resources;
    },
    drawercontrol(){
        return this.$store.state.drawernavi;
    },
    myemail(){
      this.useremail = localStorage.getItem('email');
      return this.useremail
    },
    avatartext() {
      const txt = localStorage.getItem('email') || '';
      return txt.slice(0, 2).toUpperCase(); 
    }
  },
  methods:  {
    async refresh() {
      try {
        await this.$store.dispatch('ApiGetResources'); // Wait for ApiGetResources to complete
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    },
    getDateFormat(data) {
      // Set the date and time for Nov 18, 2023, at 9 AM
      let date = new Date(data);
      
      // Format the date to YYYY-MM-DD hh:mm
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    },
  }
};
</script>

<style scoped>
/* Add custom styling for the blurred background */
.v-overlay__scrim {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.5); /* Adjust the color and opacity as needed */
}
.main{
  background-color: #3F51B5;
  padding:0px;
  margin: 0px;
  /* display: flex; */
  /* margin-top: 6vh; */
  /* height: 100vh; */

}
.selection-bar{
  width: 20%;
  height: 100%;
  background-color: white;
}
.main-container{
  width: 100%;
  min-height: 120vh; /* Set the minimum height */
  height: 100vh;
}
</style>