import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import BookingView from '../views/BookingView'
import UserView from '../views/UserView'
import EditView from '../views/EditView'
import NewBooking from '../views/NewBooking'
import Report from '../views/ReportView.vue'
import store from '../store/index.js'; // Import your Vuex store

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    }, {
        path: '/login',
        name: 'login',
        component: LoginView
    }, {
        path: '/edit',
        name: 'edit',
        component: EditView
    }, {
        path: '/Booking',
        name: 'Booking',
        component: BookingView,
        props: (route) => ({room: route.query.room}) // Pass the 'room' parameter as props
    }, {
        path: '/NewBooking',
        name: 'NewBooking',
        component: NewBooking
    }, {
        path: '/User',
        name: 'User',
        component: UserView,
        props: (route) => ({room: route.query.room}) // Pass the 'room' parameter as props
    },{
        path: '/Report',
        name: 'Report',
        component: Report,
    }, {
        path: '/about',
        name: 'about',
        component: () => import (/* webpackChunkName: "about" */
        '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // Perform an action or call an API on every route change here Example: dispatch
    // an action named 'performActionOnRouteChange' using Vuex
    // store.dispatch('performActionOnRouteChange', to);  'to' contains information
    // about the target route

    // var id = localStorage.getItem("user_id");
    // const publicRoutes = ['login', ''];

    // if (!id && !publicRoutes.includes(to.name)) {
    //     next('/');
    // } else {
    //     next();
    // }

    if ((from.path === '/') && (to.path === '/Booking')) {
        let date
        var resource_id = to.query.room
        if (store.state.redirectdate) {
            date = store
                .state
                .redirectdate
                store
                .commit('setredirectdate', '')
        } else {
            if(to.query.date){
                date = to.query.date
            }
            else{
                date = new Date();
                date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
                    date.getDate()
                ).padStart(2, '0')}`; // yyyy-mm-dd
            }
        }
        var data = {
            date: date,
            resource_id: resource_id
        }
        store.dispatch('APIgetReservationsSchedule', data)
    } else if ((to.query.room != from.query.room) && (to.query.room) && (to.query.room)) {
        let date
        var resource_id = to.query.room
        if (store.state.redirectdate) {
            date = store
                .state
                .redirectdate
                store
                .commit('setredirectdate', '')
        } else {
            date = new Date();
            date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
                date.getDate()
            ).padStart(2, '0')}`; // yyyy-mm-dd
        }
        var data = {
            date: date,
            resource_id: resource_id
        }
        store.dispatch('APIgetReservationsSchedule', data)
    }

    if (to.path === '/edit') {
        var series_id = to.query.id
        if (series_id) {
            store.dispatch('APIgetEditReservationsSchedule', series_id)
        }

    }
    // Continue with the route navigation
    next();
});

export default router
