<template>
<div>
    <v-card >
        <v-card-title>
            <h1>CRC SGH BOOKING SYSTEM</h1>
        </v-card-title>
        <v-divider />
        <div class="my-4"></div>
        <v-card-text>
            <h2>Welcome to CRC SGH BOOKING SYSTEM</h2>
            <!-- <p>Effortlessly book rooms, schedule appointments, and manage your CRC Kuching bookings with our user-friendly system.</p> -->
            
            <ul>
                <li>Explore available rooms and facilities.</li>
                <li>View CRC Kuching's event calendar.</li>
                <li>Book meeting rooms, event spaces, and more.</li>
            </ul>
        </v-card-text>
        <!-- {{  this.$store.state.Resources }} -->
        <v-row align="center" justify="center">
            <v-col cols="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" style="width: 60%; padding-left: 5%;">
                                    Name
                                </th>
                                <th class="text-left" style="width: 20%;">
                                    Action
                                </th>
                                <th class="text-left" style="width: 20%;">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in rooms" :key="item.name">
                                <td class="text-left" style="width: 60%; padding-left: 5%;">{{ item.name }}</td>
                                <td class="text-left" style="width: 20%;">
                                    <v-btn outlined color="grey darken-2" @click="addReserve( item.resource_id)">New Booking</v-btn>
                                </td>
                                <td class="text-left" style="width: 20%;">
                                    <v-btn outlined color="grey darken-2" @click="viewcalendar( item.resource_id)">View Calendar</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card>
</div>
</template>

<script>
export default {
    name: 'FrontPage',
    computed: {
        rooms() {
            return this.$store.state.Resources;
        }
    },
    data() {
        return {
            headers: [{
                    text: 'Room Name',
                    value: 'name'
                },
                {
                    text: 'Status',
                    value: 'status'
                },
                {
                    text: 'Action',
                    value: 'status'
                },
                // Add more headers as needed
            ]
        };
    },
    methods: {
        async viewcalendar(id) {
            this.$store.commit('setdisablelist', true)
            try {
                var url = '/Booking?room=' + id;
                this.$router.push(url).catch(() => {});;
            } catch (error) {
                console.error('Error fetching resources:', error);
            }
            this.$store.commit('setdisablelist', false)
        },
        addReserve(id) {
            this.$store.commit('setBookingRoomID', id)
            var url = '/NewBooking?room=' + id
            this.$router.push(url).catch(() => {});
        }
    }
}
</script>

<style scoped>
h1 {
    color: #333;
    margin: 0;
}

h2 {
    color: #555;
}

p {
    color: #777;
}
</style>
