<template>
<v-row class="d-flex align-center no-padding-no-margin">
    <v-col cols="12" class="d-flex align-center no-padding-no-margin">
        <h3>Room</h3>
    </v-col>
    <v-col cols="12" class="d-flex align-center no-padding-no-margin">
        <v-select 
            outlined
            v-model="selectedResourceID" 
            :items="RoomSelect" 
            item-text="name" 
            item-value="resource_id" 
            :rules="Rules" 
            required 
            hint="Pick a Room"
            @input="onInput"></v-select>
    </v-col>
</v-row>
</template>

<script>
export default {
    props: {
        RoomSelect: Array,
        defaultSelectedRoom: {
            default: null
        }
    },
    data: () => ({
        selectedResourceID: "",
        roomtype: "",
        Rules: [
            v => !!v || 'Room is required',
        ],
    }),
    created() {
        if (this.defaultSelectedRoom) {
            this.selectedResourceID = parseInt(this.defaultSelectedRoom)
            this.onInput()
        }
        else{
        }
    },
    watch: {
        defaultSelectedRoom: {
            immediate: true, // Trigger the watcher immediately when the component is created
            handler(newVal) {
                this.selectedResourceID = parseInt(newVal);
            },
        }
    },
    methods: {
        onInput() {
            
            this.$emit('selectedRoom', this.selectedResourceID)
        }
    }
}
</script>

<style>

</style>
