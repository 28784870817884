<template>
  
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-progress-linear :indeterminate="FormButtonLoading"></v-progress-linear>
        
          <v-card-title>
             <v-row>
              <h3>Book a room</h3>
              <v-spacer></v-spacer>
              <v-btn 
              fab
              elevation="0"
              small
              class="mr-5"
              color='deep-purple lighten-5'
              @click="goBack">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            </v-row>
          </v-card-title>
        
        <v-divider class="mx-4 mb-6"></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6" sm="12">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex align-center no-padding-no-margin">
                    <Title @title="getTitle"></Title>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex align-center no-padding-no-margin">
                      <RoomSelect :defaultSelectedRoom="defaultSelectedRoom" 
                                  :RoomSelect="getRoomSelectOptions" 
                                  @selectedRoom="getSelectedRoom"></RoomSelect>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div v-if="selectedRoomInfo.resource_type_id == 5" >
                    <TimeSelect :defaultVModel="gettimeselect"
                                :selectResourceID="selectedRoomInfo.resource_id"
                                @selectedDate="getselectedDate"
                                @selectedTimeFrom="getselectedTimeFrom"
                                @selectedTimeTo="getselectedTimeTo"></TimeSelect>
                  </div>
                  <div v-else class="d-flex align-center no-padding-no-margin">
                    <TimeSelectMeeting :defaultVModel="gettimeselect"
                                      :selectResourceID="selectedRoomInfo.resource_id"
                                      @selectedDate="getselectedDate"
                                      @selectedTimeFrom="getselectedTimeFrom"
                                      @selectedTimeTo="getselectedTimeTo"
                                      @onInput="testinginput"></TimeSelectMeeting>
                  </div>
                </v-col>
              </v-row>
           </v-col>
           <v-divider vertical></v-divider>
           <v-col cols="6"  md="6" sm="12">
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center no-padding-no-margin">
                  <Description @description="getDescription"></Description>
                </div>
             </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center no-padding-no-margin">
                  <PhoneNumber @PhoneNumber="getPhoneNumber" />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center no-padding-no-margin">
                  <BookBy @BookBy="getBookBy" />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center no-padding-no-margin">
                  <Remark @Remark="getRemark"/>
                </div>
              </v-col>
            </v-row>

           </v-col>
           
        </v-row>
        
        </v-card-text>
        <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
            <v-col cols="2" >
              <v-btn color='deep-purple lighten-5' width="100%" @click="reset()">Clear</v-btn>
            </v-col>
            <v-col cols="2" class="mr-5">
              <v-btn color='deep-purple lighten-5' width="100%" @click="submit()" :loading="FormButtonLoading">Submit</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>


      </v-card>
    </v-form>

    <v-divider></v-divider>
 
    <BookResultDialog />
  </div>
</template>


<script>
import BookResultDialog from './BookResultDialog.vue'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import PhoneNumber from './FormComponents/PhoneNumber.vue'
import Title from './FormComponents/Title.vue'
import Description from './FormComponents/Description.vue'
import RoomSelect from './FormComponents/RoomSelect.vue'
import BookBy from './FormComponents/BookBy.vue'
import TimeSelect from './FormComponents/TimeSelect.vue'
import TimeSelectMeeting from './FormComponents/TimeSelectMeeting.vue'
import Remark from './FormComponents/Remark.vue'

// import SvgIcon from '@jamescoyle/vue-icon';
// import { mdiCloseThick } from '@mdi/js';

  export default {
    components:{
      BookResultDialog,
      VueTelInputVuetify,
      PhoneNumber,
      Title,
      Description,
      RoomSelect,
      BookBy,
      TimeSelect,
      TimeSelectMeeting,
      Remark,

      // SvgIcon
    },
    data: () => ({

      // currentDateTime : this.combineDateAndTime(this.fromdate, this.Frmtimes),
      // currentDateTime2 : this.combineDateAndTime(this.fromdate, this.Totimes),

      FormData: {
          start_date: "",
          end_date: "",
          title : "",
          phonenumber : "",
          description : "",
          resource_id : "",
          resource_type_id : "",
          bookby : "",
          allow_participation  : '0',
          allow_anon_participation : '0',
          typeID : '1',  // 1: reservation; 2: blackout
          statusID : '1', // 1:created; 2:deleted ; 3:pending
          ownerID : localStorage.getItem("user_id"),  // userid
          resourceLevelID : '1', // default
          remark : ''
        },

      phone: {
        number: "",
        valid: true,
        country: undefined
      },
      // resultdialog: false,

      titleRules : '',
      descRules : '',
      bookRules : '',
      contactRules : '',
      dateRules : '',
      roomRules : '',
      fromRules : '',
      toRules : '',

      textedit: false,
      textRoomedit: false,
      textDateedit: false,
      textFromedit: '',
      textToedit: '',
      valid: false,

      selectedRoomInfo:{
        name:"",
        resource_id : "",
        resource_type_id: "",
        date: "",
        from: "",
        to: ''
      },
    }),
    computed:{
      defaultSelectedRoom(){
        if(this.$store.state.bookingRoomId)
        {
          return this.$store.state.bookingRoomId
        }
        else{
          var roomid = this.$route.query.room
          this.FormData.resource_id = roomid
          return roomid
        }
      },
      getRoomSelectOptions(){
        return this.getRoomList();
      },
      returndialog(){
        return this.$store.state.resultdialog;
      },
      FormButtonLoading(){
        return this.$store.state.FormButtonLoading;
      },
      gettimeselect(){
        return ({
          date : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          from : "",
          to :  "",
        })
      },
    },
    watch:{
      FormButtonLoading(newValue, oldValue){
        this.inputdisableflag(newValue)
      },
      selected_resourceid(newValue, oldValue) {
         if(!newValue){
            this.roomRules ='Room is required.'
          }
          else if (newValue){
            this.roomRules = ''
            this.checkRoomDate()
          }
      },
      fromdate(newValue, oldValue) {
        if(!newValue){
            this.dateRules ='Room is required.'
          }
          else if (newValue){
            this.dateRules = ''
            this.checkRoomDate()
          }

      },
      Frmtimes(newValue, oldValue) {
        
        if (newValue!=null) {
          this.textToedit=false
          this.fromRules = ''
          this.checkToRoomDate()
        }
        else{
          this.textToedit=true
          this.fromRules = 'From Time is required'
        }
        
      },
      Totimes(newValue, oldValue) {
        if (newValue!=null) {
          this.toRules = ''
        }
        else{
          this.toRules = 'To Time is required'
        }

      },
      getRoomSelectOptions(newVal){
        this.getSelectedRoom(window.location.hash.split('?room=')[1])
      }

    },

    methods:{
      testinginput(){
        console.log("testing input")
      },
      goBack() {
        this.$router.go(-1);
      },
      getRoomList(){
        return this.$store.state.Resources.map(resource => ({
                                            name: resource.name,
                                            resource_id: resource.resource_id,
                                            resource_type_id: resource.resource_type_id
                                          }));
      },
      getBookBy(value){
        this.FormData.bookby = value
      },
      getRemark(value){
        this.FormData.remark = value
      },
      getSelectedRoom(value){
        console.log('setSelectedRoom', this.$store.state.Resources) 
        if((value) && (this.$store.state.Resources.length > 0)){
           var selectedRoom = this.$store.state.Resources.find(resource => resource.resource_id == value);
           this.selectedRoomInfo.name = selectedRoom.name,
           this.selectedRoomInfo.resource_id = selectedRoom.resource_id,
           this.selectedRoomInfo.resource_type_id = selectedRoom.resource_type_id,
           this.validateRoom_Date()
        }
      },
      getselectedDate(value){
        if(value){
          console.log("date is selected")
          this.selectedRoomInfo.date = value,
          this.validateRoom_Date()
        }
      },
      getselectedTimeTo(value){
        console.log("time to is selected")
        this.selectedRoomInfo.to = value,
        this.validateRoom_Date()
      },
      validateRoom_Date(){
        if((this.selectedRoomInfo.resource_id) 
            && (this.selectedRoomInfo.date)
            && (this.selectedRoomInfo.to)
            ){
              this.FormData.start_date = this.combineDateAndTime(this.selectedRoomInfo.date, this.selectedRoomInfo.from)
              this.FormData.end_date = this.combineDateAndTime(this.selectedRoomInfo.date, this.selectedRoomInfo.to)
              this.FormData.resource_id = this.selectedRoomInfo.resource_id
              this.FormData.resource_type_id = this.selectedRoomInfo.resource_type_id
        }
        if((this.selectedRoomInfo.resource_id) 
            && (this.selectedRoomInfo.date)){
          console.log("room and date is selected, get time from")
          var data = {
            resource_id : this.selectedRoomInfo.resource_id,
            // start_date : '2023-12-31'
            start_date : this.selectedRoomInfo.date,
          }
          this.$store.dispatch('APIgetBookingStartTime', data)
        }
      },
      getselectedTimeFrom(value){
        this.selectedRoomInfo.from = value
        if  ((this.selectedRoomInfo.resource_id) 
         && (this.selectedRoomInfo.date)
         && (this.selectedRoomInfo.from))
        {
          var data = {
            resource_id : this.selectedRoomInfo.resource_id,
            start_date : this.selectedRoomInfo.date,
            from_time : this.convertTo24HourFormat(this.selectedRoomInfo.from),
          }
          this.$store.dispatch('APIgetBookingEndTime', data)
        }
      },
      getDescription(value){
        this.FormData.description = value
      },
      getTitle(value){
        this.FormData.title = value
      },
      getPhoneNumber(value){
        this.FormData.phonenumber = value
      },
      convertTo24HourFormat(time) {
            const timeRegex = /^(\d{1,2}):(\d{2}) ([APap][Mm])$/; // Regex pattern for hh:mm AM/PM format
            const match = time.match(timeRegex);

            if (!match) {
            // Handle invalid time format
            return 'Invalid Time Format';
            }

            let [, hours, minutes, meridiem] = match;

            hours = parseInt(hours, 10);
            minutes = parseInt(minutes, 10);

            if (meridiem.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
            } else if (meridiem.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
            }

            const formattedHours = ('0' + hours).slice(-2);
            const formattedMinutes = ('0' + minutes).slice(-2);

            return `${formattedHours}:${formattedMinutes}:00`;
      },
      inputdisableflag(disable) {
          this.textedit = disable;
          this.textRoomedit = disable;
          this.textDateedit = disable;
          this.textFromedit = disable;
          this.textToedit = disable;
          this.valid = disable;
        },
      checkRoomDate() {
        if(this.selected_resourceid && this.fromdate){
          this.textFromedit = false
          this.textedit = !this.textedit
          var data = {
            resource_id : this.selected_resourceid,
            start_date : this.fromdate,
          }
          this.$store.dispatch('APIgetBookingStartTime', data)
          
          this.textedit = !this.textedit
        }
      },
      reset(){
        this.$refs.form.reset()
      },
      submit(){
        var validateresult = this.$refs.form.validate()
        var userid = localStorage.getItem("user_id")
        if (userid === null){
          console.log('Please login to continue')
          this.$router.push('/login').catch(()=>{});;
        }
        else{
          
          if(validateresult)
          {
            this.$store.dispatch('APIpostReservationsSchedule', this.FormData)
          }
        }
      },
      combineDateAndTime(dateString, timeString) {
          const [time, modifier] = timeString.split(' ');
          let [hours, minutes] = time.split(':');
          if (hours === '12') {
            hours = '00';
          }
          if ((modifier === 'pm')
          || (modifier === 'PM')) {
            hours = parseInt(hours, 10) + 12;
          }
          const combinedDateTimeString = `${dateString} ${hours}:${minutes}:00`;
          const combinedDateTime = new Date(combinedDateTimeString);
          return combinedDateTime;
      },
    },
  }
</script>

<style>

.no-padding-no-margin {
  padding: 0 !important;
  margin: 0 !important;
}
.container{
  width: 1280px;
}
</style>