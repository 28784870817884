<template>
    <div>
      <div v-if="(this.$store.state.User.user_id)">
        <Main class="ma-4"/>
        <Report v-show="reportAuth"  class="ma-4"/>
      </div>
      <div v-else class="home-container">
        <BookNow/>
      </div>
    </div>
    
</template>

<script>
 import Main from '@/components/Main.vue';
 import BookNow from '@/components/BookNow.vue';
 import Report from '@/components/Report.vue'

  export default {
    name: 'Home',

    components: {
      Main,
      BookNow,
      Report
    },
    data: ()=>({
    }),
    computed:{
      reportAuth(){
        var loginid = localStorage.getItem('user_id');
        var authorise = this.$store.state.User.authorise
        var isGroupAdminManager
        var isScheduleAdminManager
        
        if (loginid == 2 || loginid == 14 || loginid == 16)
        {
          return true
        }
        else{
          return false
        }

        // if (authorise)
        // {
        //   // isScheduleAdminManager = authorise.some(item => item.name === 'Schedule Administrators');
        //   isGroupAdminManager = authorise.some(item => item.name === 'Group Administrators');
        // }
        // const isOwnUser = loginid;
        // console.log(isGroupAdminManager)
        // console.log(isOwnUser)
        // if ( isGroupAdminManager)
        // {
        //   return false
        // } 
        // else
        // {
        //   return true
        // } 
      }
    },

    methods:{
      clickme(){
        console.log(this.$store.state.user.user_id)
        // this.$store.dispatch('user_mutation/test');
      }
    }
  }
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Optional: Adjust the height as needed */
}
</style>
