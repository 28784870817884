<template>
    <v-row class="formtitle">
        <v-col cols="12"  class="formtextfield">
          <h3>Book By</h3>
        </v-col>
        <v-col cols="12" class="formtitle">
          <v-text-field
            outlined
            v-model="bookedby"
            :rules="Rules"
            required
            :placeholder="label"
            @input="onInput"
            v-on:keypress="isLetter($event)"
          ></v-text-field>
        </v-col> 
    </v-row>  
</template>

<script>
export default {
    props:{
        defaultVModel:{
            default: null
        }
    },
    data:() => ({
        bookedby : "",
        label: "Booked name is required",
        Rules: [
                v => !!v || 'Booked name is required',
                v => (v && v.length <= 85) || 'Name must be less than 85 characters',
            ],
    }),
    watch:{
        defaultVModel:{
            immediate: true, // Trigger the watcher immediately when the component is created
            handler(newVal) {
                this.bookedby = newVal;
                this.$emit('BookBy', this.bookedby)
            },
        }
    },
    methods:{
        onInput(){
            this.bookedby = this.bookedby.toLocaleUpperCase()
            this.$emit('BookBy', this.bookedby)
        },
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char) ||
                char == 'Tab' ||
                char == 'Backspace'  ||
                char == ' '||
                char.startsWith('Arrow')||
                 (char.ctrlKey))  return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
    }

}
</script>

<style>

</style>