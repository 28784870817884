<template>
    <div>
    <!-- <v-combobox
        v-model="searchInput"
        prepend-inner-icon="mdi-magnify"
        :items="items"
        item-text="name"
        item-value="name"
        clearable
        :loading="loading"
        placeholder="Search"
        @click:clear="handleClear"
        @keydown.enter="handleEnterKey"
        :search-input.sync="searchInput"
        @change="hanndleChange"
        >
    </v-combobox> -->
    <v-text-field
        class="mr-5 mb-0 pb-0"
        v-model="searchInput"
        prepend-inner-icon="mdi-magnify"
        :loading="loading"
        placeholder="Search"
        clearable
        @click:clear="handleClear"
        @keydown.enter="handleEnterKey"
        @input="hanndleChange"
    >

    </v-text-field>
</div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
    data :() => ({
        label: "Search",
        loading: false,
        cancelTokenSource: null,
        searchInput: '',
       
    }),
    watch: {
    searchInput(val){
            if(val){
                if (val.length > 0) {
                    this.startSearch()
                }
            }

        }
    },
    created(){
        this.ClearSearchResultData()
    },
   computed: {
        items () {
            return this.$store.getters['searchResultData']
        }
    },
    methods: {
        ClearSearchResultData(){
            this.$store.dispatch('setClearSearchResultData');
        },
        EmitenterEvent(){
            this.$emit("enterEvent");
        },
        hanndleChange(){
            if(this.searchInput){
                this.EmitenterEvent()
            }
            else{
                this.$store.dispatch('setClearSearchResultData');
                this.handleClear()
            }
        },
        handleEnterKey(){
            this.EmitenterEvent()
        },
        handleClear() {
            // this.ClearSearchResultData()
            this.$emit("clearEnterEvent")
        },
        startSearch() {
            this.loading = true
                this.debouncedSearch(this.searchInput);
            },
        debouncedSearch: _debounce(function (query) {
                            this.search(query);
            }, 500), 
        async search(query) {
            try{
                await this.$store.dispatch('APIgetReservationSearch', this.searchInput);
            }
            finally{
                this.cancelTokenSource = null;
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>

.v-text-field {
  padding: 1em 0 0; /* Your custom padding values */
}

</style>