<template>
<v-footer class="bg" inset>
    <v-card-text class="py-1 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>Booking CRC Kuching</strong>
    </v-card-text>
</v-footer>
</template>

<script>
export default {}
</script>

<style scoped>
.bg {
    /* height: 200px; */
    background-color: rgba(8, 6, 2, 0.21);
}
</style>
