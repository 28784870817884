<template>
  <div>
    <v-card class="ma-3">
        <searchFilter/>
    </v-card>
    <v-card class="ma-3">
        <tableData/>
    </v-card>
  </div>
</template>

<script>
import tableData from './Tabledata.vue'
import searchFilter from './searchFilter.vue'

export default {

    components: {
        tableData,
        searchFilter
    },
    data: () => ({
        
        
    }),
    computed:{
            getTitle() {
                return this.$store.getters['getTitle']
            }
        }

}
</script>

<style>

</style>