<template>
    <NewForm></NewForm>
</template>

<script>
import NewForm from '../components/NewForm.vue'

export default { 
    components:{
        NewForm
    },
    data:() => ({


    })

}
</script>

<style>

</style>