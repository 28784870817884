<template>
    <div>
      <v-card>
        <v-card-title class="headline">Password Reset</v-card-title>
        <v-card-text>
          <p class="instruction-message">
            This is your first login. Please reset your password.
          </p>
        </v-card-text>
        <v-card-text>
          <v-form @submit.prevent="resetPassword">
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-text-field autocomplete="false" v-model="returnusername" required :disabled="true"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-text-field autocomplete="false" v-model="password" label="Password" type="password" :type="passwordShow2 ? 'text' : 'password'" :append-icon="passwordShow2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordShow2 = !passwordShow2" :rules="[passwordsMatch]"
                  required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-text-field autocomplete="new-password" v-model="repeatPassword" label="Repeat Password" type="password" :type="passwordShow1 ? 'text' : 'password'" :append-icon="passwordShow1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordShow1 = !passwordShow1"
                  required :rules="[passwordsMatch]"></v-text-field>
              </v-col>
            </v-row>
            <v-alert :value="ErrorTextShow" dark type="errorstatus" border="top" transition="scale-transition">
              {{ this.$store.state.resetresulttext }}
            </v-alert>
            <v-row>
              <v-col cols="8"></v-col>
              <v-col cols="2">
                <v-btn color="primary" type="submit" :disabled="btndisable">Reset Password</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        passwordShow1: false,
        passwordShow2: false,
        password: '',
        repeatPassword: '',
      };
    },
    methods: {
      resetPassword() {
        if (this.password === this.repeatPassword) {
          console.log('Password reset initiated:');
          var data = {
            user_id: this.userid,
            password: this.password
          }
          this.$store.dispatch('apiresetpassword', data)
        } else {
          // Passwords don't match, handle this case (e.g., show error message)
          console.error('Passwords do not match');
        }
      }
    },
    created() {
      var userid = this.$store.state.reset_user_id
      if (!userid) {
        console.log('missing userid')
        this.$router.push('/').catch(() => {});
        window.location.reload();
      }
    },
    computed: {
      userid() {
        return this.$store.state.reset_user_id
      },
      returnusername: {
        get() {
          return this.$store.state.reset_username
        },
      },
      passwordsMatch() {
        return (value) =>
          value === this.password || 'Passwords do not match';
      },
      btndisable() {
        return this.$store.state.passwordbtndisable
      },
      ErrorTextShow() {
        if (this.$store.state.resetresulttext) {
          return true
        } else {
          return false
        }
      },
      errorstatus() {
        if (this.$store.state.resetresulttext == 'Password had been changed') {
          return 'success'
        } else {
          return 'error'
        }
      }
    }
  }
</script>

<style scoped>
  .instruction-message {
    font-style: italic;
    margin-bottom: 15px;
  }
  /* Add your custom styles here */
</style>