<template>
  <div class="pa-5">
   <v-row >
      <v-col md="3">
        <TimePicker @getDate="getSelectedDateFrom" :label="label.dateFrom"></TimePicker>
      </v-col>
      <v-col md="3">
        <TimePicker @getDate="getSelectedDateTo" :label="label.dateTo"></TimePicker>
      </v-col>
      <v-col md="4">
        <RoomSelect @getRoom="getSelectedResourceID" :RoomSelect="Roomlist"></RoomSelect>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col md="1">
      <v-row class="ml-1">
        <v-col md="12">
          <v-btn 
          outlined 
          :loading="Loading.Search" 
          class="custom-btn" 
          @click="searchOnClick" 
          > Search
          </v-btn>
        </v-col>
        <v-col md="12">
          <v-btn 
          outlined 
          :loading="Loading.Excel" 
          class="custom-btn" 
          @click="downloadExcel" 
          >Excel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
      
    </v-row> 
  </div>

</template>

<script>
import TimePicker from './SearchComponents/TimePicker.vue'
import RoomSelect  from './SearchComponents/RoomSelect.vue'
import * as XLSX from 'xlsx'

export default {
    components:{
      TimePicker,
      RoomSelect
    },

    data:() => ({
        Loading: {
          Search: false,
          Excel: false,
        },
        label : {
            dateFrom: "Date from",
            dateTo: "Date To"
        },
        searchData: {
          DateFrom: '',
          DateTo: '',
          resource_id: '',
        },
    }),
    computed:{
      Roomlist(){
        return this.getRoomList();
      }
    },
    methods: {
      getSelectedDateFrom(val){
        this.searchData.DateFrom = val
      },
      getSelectedDateTo(val){
        this.searchData.DateTo = val
      },
      getSelectedResourceID(val){
        console.log(val)
        this.searchData.resource_id = val;
      },
      async searchOnClick(){
        this.Loading.Search = true

        var data = {
        DateFrom: (!this.searchData.DateFrom || this.searchData.DateFrom === "") ? '0000-00-00' : this.searchData.DateFrom,
        DateTo: (!this.searchData.DateTo || this.searchData.DateTo === "") ? '0000-00-00' : this.searchData.DateTo,
        resource_id:  this.searchData.resource_id
        // resource_id:  JSON.stringify(this.searchData.resource_id)
        };
        await this.$store.dispatch('ApiPostReport', data)
        this.Loading.Search = false;
      },  
      getRoomList(){
        return this.$store.state.Resources.map(resource => ({
                                            name: resource.name,
                                            resource_id: resource.resource_id,
                                            resource_type_id: resource.resource_type_id
                                          }));
      },
      TableData(){
        return this.$store.getters['getReportTableData']
      },
      async ExcelBtn(){
        this.Loading.Excel = true;
        await this.downloadExcel()
        this.Loading.Excel = false;
      },
      async downloadExcel() {
        

        const horizontalArray = this.convertToHorizontalArray(this.TableData());
        const worksheet = XLSX.utils.aoa_to_sheet(horizontalArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');

    },
    convertToHorizontalArray(dataArray) {
      const columnNames = Object.keys(dataArray[0]);
      const horizontalArray = [columnNames];
      dataArray.forEach(item => {
        horizontalArray.push(Object.values(item));
      });
      return horizontalArray;
    }
    }


}
</script>

<style scoped>
.custom-btn {
  width: 9em;
}
</style>