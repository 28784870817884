<template>
<v-row class="d-flex align-center no-padding-no-margin">
    <v-col  cols="12" class="d-flex align-center no-padding-no-margin">
        <h3>Purpose / Test</h3>
    </v-col>
    <v-col  cols="12" class="d-flex align-center no-padding-no-margin">
        <v-text-field 
            outlined 
            v-model="title" 
            :rules="Rules" 
            hint="Key in the purpose of book this Room"
            placeholder="Purpose"
            required
            @input="onInput"></v-text-field>
    </v-col>
</v-row>
</template>

<script>
export default {
    props: {
        defaultVModel: {
            default: null
        }
    },
    data: () => ({
        title: '',
        Rules: [
            v => !!v || 'Required',
            v => (v && v.length <= 85) || 'Name must be less than 85 characters'
        ],
        textedit: false
    }),
    watch: {
        defaultVModel: {
            immediate: true, // Trigger the watcher immediately when the component is created
            handler(newVal) {
                this.title = newVal;
                this.$emit('title', this.title)
            }
        }
    },
    methods: {
        onInput() {
            if (this.title) {
                this.title = this
                    .title
                    .toUpperCase();
                this.$emit('title', this.title)
            }
        }
    }
}
</script>

<style></style>
