<template>

 <div>
     <!-- <v-dialog
        v-model="Dialog"
        persistent
        max-width="65%"
      > -->
    <v-card class="card">
      <v-card-title class="headline">Log In</v-card-title>
      <v-card-text class="contain">
        <v-form ref="form">
          <div>
            <v-text-field v-model="username" label="Username" required autocomplete="username"></v-text-field>
          </div>
          <div>
            <v-text-field v-model="password" label="Password" required :type="passwordShow ? 'text' : 'password'" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordShow = !passwordShow" autocomplete="current-password" @keyup.enter="handleEnterKey"></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <div>
        <v-alert :value="ErrorTextShow" dark type="error" border="top" transition="scale-transition">
          Username or Password incorrect
        </v-alert>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="height:'100px'" @click="CancelLogin" :disabled="CancelButtonDisable">
          Cancel
        </v-btn>
        <v-btn class="height:'100px'" @click="ClickLogIn" :loading="LoginButtonLoading">
          Log In
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- </v-dialog>-->
  </div>  
</template>

<script>
  export default {
    data: () => ({
      passwordShow: false,
      username: '',
      password: '',
      snackbar: false,
      timeout: 2000,
      errortext: '',
    }),
    created() {},
    computed: {
      Dialog() {
        return this.$store.state.LoginDialog
      },
      ErrorTextShow() {
        return this.$store.state.LoginError
      },
      LoginButtonLoading() {
        return this.$store.state.LoginButtonLoading
      },
      CancelButtonDisable() {
        return this.$store.state.CancelButtonDisable
      }
    },
    methods: {
      handleEnterKey(event) {
        if (event.key === 'Enter' && !this.$store.state.LoginButtonLoading) {
          // Trigger ClickLogIn method when Enter key is pressed
          this.ClickLogIn();
        }
      },
      CancelLogin() {
        this.$router.go(-1)
      },
      ClickLogIn() {
        var zmessage;
        if (!this.username) {
          zmessage = "username";
        }
        if (!this.password) {
          (!zmessage) ? zmessage = "password": zmessage = zmessage + " & password";
          console.log(zmessage)
        }
        if (zmessage != null) {
          this.errortext = zmessage + " cannot be empty";
          this.snackbar = !this.snackbar;
        } else {
          var data = {
            username: this.username,
            password: this.password
          }
          this.$store.commit('clearLoginStatus');
          this.$store.dispatch("userLogIn", data);
        }
      }
    }
  }
</script>

<style scoped>
  .headline {
    font-size: 15em;
    justify-content: center;
  }
  .container {
    /* background-color:yellow */
  }
  .contain {
    padding: 3em;
    /* background-color:green; */
  }
  .card {
    padding: 35px;
  }
</style>