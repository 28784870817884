<template>
  <Login></Login>
</template>

<script>
import Login from '../components/Login.vue'
export default {
    name: "login",
    components: {
      Login,
    }
}
</script>

<style>

</style>