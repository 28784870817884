<template>
  <v-row>
    <v-col cols="12" class="phonetitle">
      <h3>Contact Number</h3>
    </v-col>
    <v-col cols="12"  >
      <vue-tel-input-vuetify 
        outlined
        defaultCountry="my" 
        v-model="value" 
        :onlyCountries="['my']" 
        @input="onInput" 
        v-on:keydown="isNumber($event)" 
        mode="international" 
        :label="label"
        :placeholder="placeholder"
        :rules="Rules" />
    </v-col>  
  </v-row>
</template>
  
<script>
  import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"


  export default {
    components: {
      VueTelInputVuetify,
    },
    name: "Example",
    props: {
      defaultVModel: {
        default: null
      }
    },
    data() {
      return {
        label : '',
        placeholder: '+60101234567',
        phone: {
          number: "",
          valid: false,
          country: undefined
        },
        Rules: [
          v => !!v || 'Phone Number is required',
          v => (v && v.length <= 85) || 'Name must be less than 85 characters',
        ],
        value: '',
        prefix: "+60",
      };
    },
    watch: {
      defaultVModel: {
        immediate: true, // Trigger the watcher immediately when the component is created
        handler(newVal) {
          this.value = newVal;
          this.$emit('PhoneNumber', newVal)
        },
      }
    },
    methods: {
      onInput(formattedNumber, {
        number,
        valid,
        country
      }) {
        this.phone.number = number.international;
        this.phone.valid = valid;
        this.prefix = country.dialCode;
        this.phone.country = country && country.name;
        this.$emit('PhoneNumber', number.international)
      },
      isNumber(e) {
        let key = e.key;
        let keyCode = e.keyCode;
        if ((/^[0-9-+]+$/.test(key)) ||
          key == 'Tab' ||
          key == 'Backspace' ||
          key == 'Delete' ||
          key.startsWith('Arrow') ||
          (e.ctrlKey)) return true;
        else e.preventDefault()
      }
    }
  };
</script>
  
<style scoped>
.phonetitle {
  display: flex;
    align-items: center;
    padding: 0;
    padding-left: 10px;
    margin: 0;
}
</style>