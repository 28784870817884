<template>
  <div>
    <v-app-bar app class="toolbar" clipped-left>
      <!-- 20240117 - removed app bar nav feature -->
      <!-- <v-app-bar-nav-icon @click="AppBarIsCLicked"></v-app-bar-nav-icon> -->
      <v-toolbar-title @click="NavigateHome" class="hover-effect">
        <img src="../assets/CRCTitle1.png" alt="Image" style="vertical-align: middle; 
               margin-right: 10px;width: 15%; 
               ">
      </v-toolbar-title>
      <v-spacer></v-spacer>
       <div class="username">{{ getusername }} </div>
      <v-btn v-if="!(this.$route.path == '/login')" target="_blank" text @click="login()">
        <Button>></Button>
      </v-btn>
    </v-app-bar>
    <!-- <NaviDrawer/> -->
  </div>
</template>

<script>
  import NaviDrawer from './AppBarComponents/NaviDrawer.vue'
  import Button from './AppBarComponents/Button.vue'
  export default {
    components: {
      NaviDrawer,
      Button,
    },
    data: () => ({
    }),
    created() {},
    computed: {
      mytitle() {
        return this.$store.state.AppBar.title;
      },
      getusername(){
        var username
        username  = this.$store.state.User.fname + this.$store.state.User.lname
        return username
      },
    },
    methods: {
      AppBarIsCLicked() {
        this.$store.commit('navidrawer');
      },
      login() {
        const path = '/'
        var session_token = localStorage.getItem("session_token")
        if (session_token){
             this.$store.dispatch('userLogout');
              if (this.$route.path !== path)
              {
                this.$router.push('/').then(() => {
                            window.location.reload(true);
                          });
              }
              else{
                window.location.reload(true);
              }
        }
        else{
          this.$router.push('/login').catch(() => {});;
        }
      },
      NavigateHome() {
        this.$router.push('/').catch(() => {});;
      }
    }
  }
</script>

<style scpoed>
  .toolbar {
    background-color: #fff;
  }
  /* CSS styles for the hover effect */
  .hover-effect:hover {
    /* Define your hover styles here */
    /* For example, change background color or add a shadow */
    cursor: pointer;
    /* Show a pointer cursor on hover */
    /* Add any other styles you want to apply on hover */
  }

.username {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px; 
  color: #333; 
}
</style>