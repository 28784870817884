<template>
  <v-card class="selection-bar">
    <v-loading v-if="this.$store.state.CalendarLoading" />
    <div v-else>
      <v-row no-gutters>
        <v-col cols="6" :style="{  fontSize: '1.5em', 'font-weight': 'bold' }">
          {{ gettitle }}
        </v-col>
        <v-col cols="5">
          <p class="text-md-right pr-md-3">Date: {{ currentTime }}</p>
        </v-col>
        <v-col cols="1">
          <v-btn
              fab
              elevation="0"
              small
              class="mr-5"
              color='deep-purple lighten-5'
          @click="goBack"><v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                <v-btn color="white" @click="pickadate">{{ $refs.calendar.title }}</v-btn>
              </v-toolbar-title>
       <v-menu
        v-model="datepicker.open"
        :close-on-content-click="false"
        :nudge-right="datepicker.x - 40"
        :nudge-bottom="datepicker.y + 20"
        transition="scale-transition"
        offset-y
        
        min-width="auto"
      >
        <v-date-picker
          v-model="datepicker.date"
          @input="datepickerOnInput"
        ></v-date-picker>
      </v-menu>

              <v-spacer></v-spacer>
              <SearchTextField 
                @enterEvent="handleEnterSearchEvent"
                @clearEnterEvent="handleClearEnter"
                 />
              <v-btn outlined class="mr-4" color="grey darken-2" @click="addReserve">Add New</v-btn>
            </v-toolbar>
          </v-sheet>
          <v-sheet v-show="false">
            <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
          </v-sheet>
          <v-sheet
            v-show="SearchTable">
            <TableData/>
          </v-sheet>
          <v-sheet 
           v-show="!SearchTable"
            height="500">
            <v-calendar 
              ref="calendar" 
              v-model="focus" 
              color="error" 
              type="week" 
              first-interval="15" 
              interval-count="19" 
              interval-minutes="30" 
              interval-height="40" 
              interval-width="60" 
              category-show-all 
              :events="schedules" 
              @click:event="showEvent">
            </v-calendar>
            <!-- <v-calendar-->
            <!-- @mousedown:time="startTime"
                  @mouseup:time="endDrag"
                  @mouseleave.native="cancelDrag"
                  @mousemove:time="mouseMove" -->
          </v-sheet>
        </v-col>
      </v-row>
      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
        <CalendarDialog @Cancel="CloseCalendarDialog"></CalendarDialog>
      </v-menu>
    </div>
  </v-card>
</template>

<script>
  import CalendarDialog from './CalendarComponents/CalendarDialog.vue'
  import SearchTextField from './CalendarComponents/SearchTextField.vue';
  import TableData from './CalendarComponents/Search/Tabledata.vue';

  export default {
    props: ['room'], // Define 'room' as a prop
    components: {
      CalendarDialog,
      SearchTextField,
      TableData
    },
    data: () => ({
      SearchTable: false,
      datepicker: {
                    date : '',
                    open : false,
                    x: '',
                    y: '',
                  },
      apiflag: false,
      Loading: true,
      resource_id: '',
      currentTime: '',
      focus: '',
      events: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStacrt: null,
      createEnd: null,
      extendOriginal: null,
    }),
    mounted() {
      // this.setToday();
      // Update the time every second
      this.timer = setInterval(() => {
        this.currentTime = new Date().toLocaleTimeString();
      }, 1000);
    },
    computed: {
      gettitle() {
        if(this.$store.state.Resources)
        {
          var list = this.$store.state.Resources
          var roomId = this.$route.query.room
          const selectedResource = list.find(item => item.resource_id == parseInt(roomId));
          return selectedResource.name
        }
      },
      schedules() {
        this.events = []
        this.events = this.$store.state.ReservationSchedule
        return this.events
      },
      reservationSchedule() {
        return this.$store.state.Resources
      }
    },
    created() {
      if(this.$route.query.date)
      {
        this.focus = new Date(this.$route.query.date)
      }
      else{
        this.focus = new Date()
      }
    
    },
    watch: {
      reservationSchedule(newVal, oldVal) {
        if (newVal) {
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
      handleClearEnter(){
        if(this.SearchTable){
          this.SearchTable = false
        }
      },
      handleEnterSearchEvent(){
        if(!this.SearchTable){
          this.SearchTable = true
        }
      },
      goBack() {
      this.$router.go(-1); // This will go back one step in the browser history
      },
      pickadate(event){
        this.datepicker.x = event.clientX
        this.datepicker.y = event.clientY
        this.datepicker.open = true
      },
      datepickerOnInput(){
        this.datepicker.open = false
        this.focus = this.datepicker.date
        this.getSchedule()

      },
      async checkAndFetchData() {
        const resources = this.$store.state.Resources;
        var resource_id = window.location.hash.split('?room=')[1];
        if (Array.isArray(resources)) {
          const checkid = resources.find(resource => resource_id === resource_id);
          if (checkid) {
            this.getSchedule()
          }
        }
      },
      getResourceID() {
        return this.$store.state.ResourceID;
      },
      startDrag({
        event,
        timed
      }) {
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
      },
      startTime(tms) {
        const mouse = this.toTime(tms)
        var searchdate = new Date(this.roundTime(mouse))
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start
          this.dragTime = mouse - start
        } else if (this.isDateWithinEvents(searchdate)) {
          this.createStart = this.roundTime(mouse)
          this.createEnd = this.addtime(this.createStart, 30)
          var start = new Date(this.createStart)
          var end = new Date(this.createEnd)
          this.createEvent = {
            name: `Event #${this.events.length}`,
            color: this.rndElement(this.colors),
            start: start,
            end: end,
            timed: true,
          }
          this.events.push(this.createEvent)
        }
      },
      mouseMove(tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration
          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)
          this.createEvent.start = min
          this.createEvent.end = max
        }
      },
      extendBottom(event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
      roundTime(time, down = true) {
        const roundTo = 30 // minutes
        const roundDownTime = roundTo * 60 * 1000
        return down ?
          time - time % roundDownTime :
          time + (roundDownTime - (time % roundDownTime))
      },
      endDrag() {
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag() {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
              this.events.splice(i, 1)
            }
          }
        }
        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      toTime(tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      getEventColor(event) {
        return event.color
      },
      setToday() {
        this.focus = ''
        this.getSchedule()
      },
      prev() {
        this.$refs.calendar.prev();
        this.getSchedule()
      },
      next() {
        this.$refs.calendar.next()
        this.getSchedule()
      },
      getSchedule() {
        var resource_id = this.$route.query.room;
        var data = {
          date: this.getDateFormat(this.focus),
          resource_id: resource_id
        }
        this.$store.dispatch('APIgetReservationsSchedule', data)
      },
      showEvent({ nativeEvent,  event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          this.$store.commit('calenderevent', event);
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }
        if (this.selectedOpen) {
          this.selectedOpen = true
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
      fetchEvents({
        start,
        end
      }) {
        const events = []
        const min = new Date(`${start.date}T08:00:00`)
        const max = new Date(`${end.date}T17:00:00`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)
        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)
          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
            category: this.categories[this.rnd(0, this.categories.length - 1)],
          })
        }
        // this.events = events
      },
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      rndElement(arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
      addtime(mytimestamp, time) {
        const timestamp = mytimestamp;
        const date = new Date(timestamp);
        date.setMinutes(date.getMinutes() + time);
        return date.getTime();
      },
      CloseCalendarDialog() {
        this.selectedOpen = false
      },
      getDateFormat(data) {
        var date
        if (data)
        {
          date = new Date(data);
        }
        else{
          date = new Date();
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      isDateWithinEvents(dateToCheck) {
        for (const event of this.events) {
          const startDate = new Date(event.start); // Assuming start_date is the property name for start date
          const endDate = new Date(event.end); // Assuming end_date is the property name for end date
          // Check if the date falls within the range of start_date and end_date
          if (dateToCheck >= startDate && dateToCheck <= endDate) {
            return false; // Date is within the range of an event, return false
          }
        }
        return true; // Date doesn't fall within any event range, return true
      },
      addReserve() {
        var roomid = window.location.hash.split('?room=')[1]
        this.$store.commit('setBookingRoomID', roomid)
        var url = '/NewBooking?room=' + roomid
        this.$router.push(url).catch(() => {});
      }
    },
  }
</script>

<style scoped lang="scss">
  .selection-bar {
    margin: 2em;
    min-height: 45em;
    padding-top: 2em;
    padding-left: 10px;
    padding-right: 10px;
  }
  .calendar-select {
    display: flex;
    background-color: gray;
  }
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 10px;
    background-color: #1867c0;
    color: #fff;
    border: 1px solid #1867c0;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
    left: 4px;
    margin-right: 8px;
    position: relative;
  }
  .my-event.with-time {
    position: absolute;
    right: 4px;
    margin-right: 0px;
  }
  .v-event-draggable {
    padding-left: 6px;
  }
  .v-event-timed {
    user-select: none;
    -webkit-user-select: none;
  }
  .v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;
    &::after {
      display: none;
      position: absolute;
      left: 50%;
      height: 4px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      width: 16px;
      margin-left: -8px;
      opacity: 0.8;
      content: '';
    }
    &:hover::after {
      display: block;
    }
  }
</style>