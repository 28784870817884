<template>
 <div>
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
            v-model="date"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="onInput"
        ></v-date-picker>
      </v-menu>
    </div>
</template>

<script>
export default {
    props:{
        label: {
            default: null,
            type: String
        },
        iniDate: {
           default: null,
           type: String
        }
    },
    data:() =>({
        date: '',
        menu2: false,
    }),

    methods:{
        onInput(){
            if(this.date){
                this.menu2 = false
                this.$emit('getDate', this.date )
            }
        }
    }
}
</script>

<style>

</style>