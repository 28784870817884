<template>
        <v-list-group
            value="false"
            no-action
            :sub-group="subgroup"
        >
        <template v-slot:activator>
            <v-list-item-content>
            <v-list-item-title>{{ items.title }}</v-list-item-title>
            </v-list-item-content>
        </template>
            <div v-for="(item, index) in items.items" :key="index">
                <div v-if="item.items && item.items.length">
                    <sub-component 
                      :subgroup="IsSubGroup" :items="item"></sub-component>
                </div>
                <div v-else>
                    <ListItem :items="item" ></ListItem>
                </div>
            </div>
        
        </v-list-group>
</template>

<script>
import ListItem from '../ListItem.vue';
// import SubGroupVue from './SubGroup.vue';
export default {
    name: 'mSubGroup',
    props: {
        items : {
            default : []
        },
        subgroup : {
            type: Boolean
        }
    },
    created(){
    },
    computed:{
        IsSubGroup(){
             return this.items.items && this.items.items.length > 0;
        },
    },
    components:{
        ListItem,
    },
    methods: {
    }

}
</script>

<style>

</style>