<template>

<div>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
        <v-progress-linear :indeterminate="FormButtonLoading"></v-progress-linear>
        <v-card-title>
          <v-row>
              <h3 >Edit room</h3>
              <v-spacer></v-spacer>
              <v-btn 
              fab
              elevation="0"
              small
              class="mr-5"
              color='deep-purple lighten-5'
              @click="goBack">
              <v-icon>mdi-arrow-left</v-icon>
              <!-- <svg-icon type="mdi" :path="path"></svg-icon> -->
            </v-btn>
            </v-row>

        </v-card-title>
        <v-divider class="mx-4 mb-6"></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6" sm="12">
                <v-row>
                  <v-col cols="12">
                  <div class="d-flex align-center no-padding-no-margin">
                    <Title :defaultVModel="setFormEditData.title" @title="setTitle"></Title>
                  </div>
                </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                  <div class="d-flex align-center no-padding-no-margin">
                    <RoomSelect :defaultSelectedRoom="setFormEditData.selected_resourceid" 
                                :RoomSelect="getRoomSelectOptions" 
                                @selectedRoom="setSelectedRoom"
                                @setresourceid="setresourceid"></RoomSelect>
                  </div>    
                </v-col>      
                </v-row>
                <v-row>
                  <v-col cols="12">
                  <div v-if="selectedRoomInfo.resource_type_id == 5" class="d-flex align-center no-padding-no-margin">
                    <TimeSelect :defaultVModel="gettimeselect" 
                                :selectResourceID="setFormEditData.selected_resourceid" 
                                @selectedDate="setselectedDate"
                                @selectedTimeFrom="setselectedTimeFrom" 
                                @selectedTimeTo="setselectedTimeTo"></TimeSelect>
                  </div>      
                  <div v-else class="d-flex align-center no-padding-no-margin">
                    <TimeSelectMeeting  :defaultVModel="gettimeselect"  
                                        :selectResourceID="selectedRoomInfo.resource_id" 
                                        @selectedDate="setselectedDate" 
                                        @selectedTimeFrom="setselectedTimeFrom" 
                                        @selectedTimeTo="setselectedTimeTo"></TimeSelectMeeting>
                  </div> 
                </v-col>
                </v-row>
              </v-col>
              <v-divider vertical></v-divider>
            <v-col cols="6" md="6" sm="12">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex align-center no-padding-no-margin">
                    <Description :defaultVModel="setFormEditData.description" @description="setDescription"></Description>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                <div class="d-flex align-center no-padding-no-margin">
                  <PhoneNumber :defaultVModel="setFormEditData.phonenumber" @PhoneNumber="setPhoneNumber"/>
                </div>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="12">
              <div class="d-flex align-center no-padding-no-margin">
                <BookBy :defaultVModel="setFormEditData.bookedby"  @BookBy="setBookBy"/>
              </div> 
             </v-col>
              </v-row>
              <v-row>
              <v-col cols="12">
                <div class="d-flex align-center no-padding-no-margin">
                  <Remark :defaultVModel="setFormEditData.remark" @Remark="setRemark"/>
                </div>
              </v-col>
            </v-row>
           
            </v-col>
          </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="2" class="mr-5">
                <v-btn color='deep-purple lighten-5' width="100%" @click="submit()" :loading="FormButtonLoading" >Submit</v-btn>
              </v-col>  
        </v-row>
          </v-card-actions>  
          </v-card>
  </v-form>
  <BookResultDialog :formeditdate="this.FormEditData.datecreated"  :formeditresourceid="this.FormEditData.selected_resourceid"/>
      <!-- <div>
        <v-snackbar
            v-model="snackbar.snackbar"
            :timeout="snackbar.timeout"
          >
            {{ snackbar.text }}
            <template v-slot:actions>
              <v-btn
                @click="snackbar.snackbar = false"
              >
                Close
              </v-btn></template>
          </v-snackbar>
      </div> -->
</div>
  </template>
<script>
import BookResultDialog from './BookResultDialog.vue'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import PhoneNumber from './FormComponents/PhoneNumber.vue'
import Title from './FormComponents/Title.vue'
import Description from './FormComponents/Description.vue'
import RoomSelect from './FormComponents/RoomSelect.vue'
import BookBy from './FormComponents/BookBy.vue'
import TimeSelect from './FormComponents/TimeSelect.vue'
import TimeSelectMeeting from './FormComponents/TimeSelectMeeting.vue'
import Remark from './FormComponents/Remark.vue'

  export default {
    props:{
      FormEditData: {
        default: null
      }
    },
    components:{
      BookResultDialog,
      VueTelInputVuetify,
      PhoneNumber,
      Title,
      Description,
      RoomSelect,
      BookBy,
      TimeSelect,
      TimeSelectMeeting,
      Remark,
    },
    data: () => ({
      valid: true,
      ini:true,
      snackbar: {
        text: 'Edit Success',
        snackbar: false,
        timeout: 2000,
      },

      phone: {
        number: "",
        // valid: false,
        country: undefined
      },
      selectedRoomInfo:{
        name:"",
        resource_id : "",
        resource_type_id: "",
        date: "",
        from: "",
        to: ''
      }

    }),
    computed:{
      setFormEditData(){
        // this.validateRoom_Date()
        console.log("computed")
        this.inivalidate()
        return this.FormEditData
      },
      getRoomSelectOptions(){
        return this.getRoomList();
      },
      gettimeselect(){
        return ({
          date : this.FormEditData.datecreated,
          from : this.FormEditData.Frmtimes,
          to :  this.FormEditData.Totimes
        })
      },
      FormButtonLoading(){
        return this.$store.state.FormButtonLoading;
      },
    },
    created(){
      if(this.FormEditData.selected_resourceid){
        this.returnFormEDitData()
      }
    },
    methods:{
      returnFormEDitData(){
         return this.FormEditData.selected_resourceid
      },
      goBack() {
        this.$router.go(-1);
      },
      getRoomList(){
        return this.$store.state.Resources.map(resource => ({
                                            name: resource.name,
                                            resource_id: resource.resource_id,
                                            resource_type_id: resource.resource_type_id
                                          }));
      },
      setTitle(value){
        // this.FormData.title = value
        this.FormEditData.title = value
        console.log("sdfsf",this.FormEditData)
      },
      setDescription(value){
       // this.FormData.description = value
        this.FormEditData.description = value
      },
      setSelectedRoom(value){
        if((value) && (this.$store.state.Resources.length > 0)){
          
           var selectedRoom = this.$store.state.Resources.find(resource => resource.resource_id == value);
           this.selectedRoomInfo.name = selectedRoom.name,
           this.selectedRoomInfo.resource_id = selectedRoom.resource_id,
           this.selectedRoomInfo.resource_type_id = selectedRoom.resource_type_id,

          //  this.FormData.resource_id = selectedRoom.resource_id,
          //  this.FormData.resource_type_id = selectedRoom.resource_type_id,

           this.FormEditData.selected_resourceid = selectedRoom.resource_id,
           this.FormEditData.resource_type_id = selectedRoom.resource_type_id,
            console.log("roomselect1", this.FormEditData)
           this.validateRoom_Date()
        }
      },
      setPhoneNumber(value){
        // this.FormData.phonenumber = value
        this.FormEditData.phonenumber = value
      },
      setBookBy(value){
        this.FormEditData.bookby = value
      },
      setRemark(value){
        this.FormEditData.remark = value
      },
      setselectedDate(value){
        if(value){
          this.selectedRoomInfo.date = value
          // this.FormData.selectedDate = value
          this.FormEditData.datecreated = value
          this.validateRoom_Date()
        }
      },
      setselectedTimeFrom(value){

        this.selectedRoomInfo.from = value
        // this.FormData.selectedTimeFrom = value
        this.FormEditData.Frmtimes = value

        if  ((this.FormEditData.selected_resourceid) 
         && (this.FormEditData.datecreated)
         && (this.FormEditData.Frmtimes))
        {
          var data = {
            resource_id : this.FormEditData.selected_resourceid,
            start_date : this.FormEditData.datecreated,
            from_time : this.convertTo24HourFormat(this.FormEditData.Frmtimes),
          }
          this.$store.dispatch('APIgetBookingEndTime', data)
        }
      },
      setselectedTimeTo(value){
        // this.FormData.selectedTimeTo = value
        this.FormEditData.Totimes = value
      },
      setresourceid(value){
      },
// ////////////////////////////////////////////////////////////////////////
      inivalidate(){
         if((this.FormEditData.selected_resourceid) 
            && (this.FormEditData.datecreated)
            && (this.ini))
          {
            this.ini = false
            
            var data = {
            resource_id : this.FormEditData.selected_resourceid,
            start_date : this.FormEditData.datecreated,
          }
          console.log('date', data)
          this.$store.dispatch('APIgetBookingStartTime', data)
          }
      },
      validateRoom_Date(){
        if((this.FormEditData.selected_resourceid) 
            && (this.FormEditData.datecreated))
        {
          console.log("checking available time . . .")
          var data = {
            resource_id : this.FormEditData.selected_resourceid,
            start_date : this.FormEditData.datecreated,
          }
          console.log('date', data)
          this.$store.dispatch('APIgetBookingStartTime', data)
        }

        // if((this.selectedRoomInfo.resource_id) 
        //     && (this.selectedRoomInfo.date)
        //     && (this.selectedRoomInfo.to)
        //     ){
              
        //       this.FormData.start_date = this.combineDateAndTime(this.selectedRoomInfo.date, this.selectedRoomInfo.from)
        //       this.FormData.end_date = this.combineDateAndTime(this.selectedRoomInfo.date, this.selectedRoomInfo.to)
        //       this.FormData.resource_id = this.selectedRoomInfo.resource_id
        //       this.FormData.resource_type_id = this.selectedRoomInfo.resource_type_id
        // }
        // else if((this.selectedRoomInfo.resource_id) 
        //     && (this.selectedRoomInfo.date)){
        //   console.log("room and date is selected, get time from")
        //   var data = {
        //     resource_id : this.selectedRoomInfo.resource_id,
        //     // start_date : '2023-12-31'
        //     start_date : this.selectedRoomInfo.date,
        //   }
        //   this.$store.dispatch('APIgetBookingStartTime', data)
        // }
      },
      
      
      
      convertTo24HourFormat(time) {
            const timeRegex = /^(\d{1,2}):(\d{2}) ([APap][Mm])$/; // Regex pattern for hh:mm AM/PM format
            const match = time.match(timeRegex);

            if (!match) {
            // Handle invalid time format
            return 'Invalid Time Format';
            }

            let [, hours, minutes, meridiem] = match;

            hours = parseInt(hours, 10);
            minutes = parseInt(minutes, 10);

            if (meridiem.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
            } else if (meridiem.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
            }

            const formattedHours = ('0' + hours).slice(-2);
            const formattedMinutes = ('0' + minutes).slice(-2);

            return `${formattedHours}:${formattedMinutes}:00`;
      },
      checkRoomDate() {
        if(this.selected_resourceid && this.fromdate){
          this.textFromedit = false
          this.textedit = !this.textedit
          var data = {
            resource_id : this.selected_resourceid,
            start_date : this.fromdate,
          }
          this.$store.dispatch('APIgetBookingStartTime', data)
          
          this.textedit = !this.textedit
        }
      },

      reset(){
      },
       async submit(){
          var validateresult = this.$refs.form.validate()
          var data = {
            currentDateTime : this.combineDateAndTime(this.FormEditData.datecreated,this.FormEditData.Frmtimes),
            currentDateTime2 : this.combineDateAndTime(this.FormEditData.datecreated, this.FormEditData.Totimes),
            title  : this.FormEditData.title,
            description : this.FormEditData.description,
            allow_participation  : '0',
            allow_anon_participation : '0',
            typeID : '1',  // 1: reservation; 2: blackout
            statusID : '1', // 1:created; 2:deleted ; 3:pending
            ownerID : localStorage.getItem("user_id"),  // userid
            resourceLevelID : '1', // default
            resourceID : this.FormEditData.selected_resourceid,
            contactno : this.FormEditData.phonenumber,
            bookby : this.FormEditData.bookby,
            remark: this.FormEditData.remark,
            series_id : this.$route.query.id
          }

        var userid = localStorage.getItem("user_id")
        if (userid === null){
          console.log('Please login to continue')
          this.$router.push('/login').catch(()=>{});;
        }
        else{
          if(validateresult)
          {
            await this.$store.dispatch('APIupdateReservationsSchedule', data)
             this.snackbar.snackbar = true
          }
          else{
            this.$refs.form.validate()
          }
        }
      },
      // validateinputvalue(formData){
      //   for (const key in formData) {
      //     if (formData.hasOwnProperty(key) && (formData[key] === null || formData[key] === undefined || formData[key] === "")) {
      //       return false;
      //     }
      //   }
      //   return formData;
      // },
      combineDateAndTime(dateString, timeString) {
          const [time, modifier] = timeString.split(' ');
          let [hours, minutes] = time.split(':');
          if (hours === '12') {
            hours = '00';
          }
          if ((modifier === 'pm')
          || (modifier === 'PM')) {
            hours = parseInt(hours, 10) + 12;
          }
          const combinedDateTimeString = `${dateString} ${hours}:${minutes}:00`;
          const combinedDateTime = new Date(combinedDateTimeString);
          return combinedDateTime;
      },
    },
  }
</script>

<style>

.no-padding-no-margin {
  padding: 0 !important;
  margin: 0 !important;
}
</style>